import React, { useState, useEffect, useRef } from "react";
import "./HomePage.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuoteLeft,
  faQuoteRight,
  faCircleRight,
  faCircleLeft,
  faChevronLeft,
  faChevronRight,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
// import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import axios from "axios";
import ScrollToTop from "../../components/scrolltotop/ScrollToTop";
import mobile from "../../assets/images/mobileusing.png";
import play from "../../assets/images/play.png";
import Appstore from "../../assets/images/Appstore.png";
import { useInView } from "react-intersection-observer";
import CountUp from "react-countup";
import BASE_URL from "../../components/link/link";
// import Scrollbar from "../../components/scrollbar/Scrollbar";
// import ads from "../../assets/images/Posterimage.png";
import { Helmet } from "react-helmet";

const HomePage = () => {
  const [homepageData, setHomepageData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentIndex, setCurrentIndex] = React.useState(0);

  // const [expandedCards, setExpandedCards] = useState({});
  // const [feedbackData, setFeedbackData] = useState([]);
  // const scrollContainerRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [currentPopupIndex, setCurrentPopupIndex] = useState(0);
  const [showPopup, setShowPopup] = useState(true);

  useEffect(() => {
    // Fetch the popup data from the backend
    const fetchPopupData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/popups/all`);
        const data = await response.json();

        // console.log("Fetched popup data:", data); // Debugging
        if (data && data.length > 0) {
          setPopupData(data); // Store all popup items
          setCurrentPopupIndex(0); // Start with the first popup
        }
      } catch (error) {
        console.error("Error fetching popup data:", error);
      }
    };

    fetchPopupData();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);

    // Show the next popup immediately if there are more popups left
    if (currentPopupIndex < popupData.length - 1) {
      setCurrentPopupIndex((prevIndex) => prevIndex + 1);
      setShowPopup(true); // Show the next popup immediately
    }
  };

  // Fetch homepage data on component mount
  useEffect(() => {
    const fetchHomepageData = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/homepage/all`);
        if (response.data && response.data.data) {
          setHomepageData(response.data.data); // Store the full data object
          setLoading(false);
        }
      } catch (err) {
        setError("Failed to fetch homepage data");
        setLoading(false);
      }
    };

    fetchHomepageData();
  }, []);

  // // Render loading, error, or homepage content
  // if (loading) return <p>Loading...</p>;
  // if (error) return <p>{error}</p>;

  const { ref, inView } = useInView({
    triggerOnce: true, // This ensures the animation only happens the first time the section is in view
    triggerMargin: "0px", // Optional, controls when the section is considered in view
  });

  useEffect(() => {
    if (inView) {
      setIsVisible(true); // Set to true when the section is in view
    }
  }, [inView]);

  const servicesSectionRef = useRef(null);
  const classesSectionRef = useRef(null);
  const feedbackSectionRef = useRef(null);

  useEffect(() => {
    const scrollInterval = setInterval(() => {
      if (feedbackSectionRef.current) {
        const { scrollLeft, scrollWidth, clientWidth } = feedbackSectionRef.current;
        const nextScroll = scrollLeft + clientWidth;

        if (nextScroll >= scrollWidth) {
          feedbackSectionRef.current.scrollTo({ left: 0, behavior: "smooth" });
        } else {
          feedbackSectionRef.current.scrollTo({ left: nextScroll, behavior: "smooth" });
        }
      }
    }, 5000); // Scroll every 5 seconds

    return () => clearInterval(scrollInterval); // Cleanup interval on unmount
  }, []);

  const scrollServiceLeft = (ref) => {
    if (ref.current) {
      const card = ref.current.querySelector(".service-card");
      if (card) {
        const cardWidth = card.offsetWidth;
        ref.current.scrollBy({
          left: -cardWidth,
          behavior: "smooth",
        });
        // } else {
        //   console.warn("No feedback cards found to calculate width.");
      }
    }
  };

  const scrollServiceRight = (ref) => {
    if (ref.current) {
      const card = ref.current.querySelector(".service-card");
      if (card) {
        const cardWidth = card.offsetWidth;
        ref.current.scrollBy({
          left: cardWidth,
          behavior: "smooth",
        });
        // } else {
        //   console.warn("No feedback cards found to calculate width.");
      }
    }
  };

  const scrollLeft = (ref) => {
    if (ref.current) {
      const card = ref.current.querySelector(".class-card");
      if (card) {
        const cardWidth = card.offsetWidth;
        ref.current.scrollBy({
          left: -cardWidth,
          behavior: "smooth",
        });
        // } else {
        //   console.warn("No class cards found to calculate width.");
      }
    }
  };

  const scrollRight = (ref) => {
    if (ref.current) {
      const card = ref.current.querySelector(".class-card");
      if (card) {
        const cardWidth = card.offsetWidth;
        ref.current.scrollBy({
          left: cardWidth,
          behavior: "smooth",
        });
        // } else {
        //   console.warn("No class cards found to calculate width.");
      }
    }
  };

  const scrollFeedbackLeft = (ref) => {
    if (ref.current) {
      const card = ref.current.querySelector(".feedback-card");
      if (card) {
        const cardWidth = card.offsetWidth;
        ref.current.scrollBy({
          left: -cardWidth,
          behavior: "smooth",
        });
        // } else {
        //   console.warn("No feedback cards found to calculate width.");
      }
    }
  };

  const scrollFeedbackRight = (ref) => {
    if (ref.current) {
      const card = ref.current.querySelector(".feedback-card");
      if (card) {
        const cardWidth = card.offsetWidth;
        ref.current.scrollBy({
          left: cardWidth,
          behavior: "smooth",
        });
        // } else {
        //   console.warn("No feedback cards found to calculate width.");
      }
    }
  };

  // Automatic carousel scrolling
  useEffect(() => {
    if (
      homepageData &&
      homepageData.bannerImages &&
      homepageData.bannerImages.length > 0
    ) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % homepageData.bannerImages.length
        );
      }, 4000); // Change every 4 seconds

      return () => clearInterval(interval); // Cleanup the interval on unmount
    }
  }, [homepageData]);

  const nextSlide = () => {
    setCurrentIndex((currentIndex + 1) % homepageData.bannerImages.length);
  };

  const prevSlide = () => {
    setCurrentIndex(
      (currentIndex - 1 + homepageData.bannerImages.length) %
        homepageData.bannerImages.length
    );
  };

  // Scroll logic for Exams and Notifications
  useEffect(() => {
    const examsList = document.querySelector(".exams-grid"); // Replace with your class or id
    const notificationsList = document.querySelector(".notifications-list"); // Replace with your class or id

    if (!examsList || !notificationsList) return; // Exit if the elements are not found

    let isHoveredExams = false; // Track if the user is hovering over the exams element
    let isHoveredNotifications = false; // Track if the user is hovering over the notifications element

    const startScrolling = (list, isHoveredFlag) => {
      return setInterval(() => {
        if (!isHoveredFlag()) {
          // Only scroll if not hovered
          if (list.scrollTop + list.clientHeight >= list.scrollHeight) {
            list.scrollTop = 0; // Reset to the top
          } else {
            list.scrollTop += 1; // Scroll down by 1 unit
          }
        }
      }, 100); // Adjust the speed here
    };

    // Scroll intervals for both lists
    let examsScrollInterval = startScrolling(examsList, () => isHoveredExams);
    let notificationsScrollInterval = startScrolling(
      notificationsList,
      () => isHoveredNotifications
    );

    // Stop scrolling when hovering over the exams list
    const stopScrollingExams = () => {
      isHoveredExams = true;
    };

    // Resume scrolling when the mouse leaves the exams list
    const resumeScrollingExams = () => {
      isHoveredExams = false;
    };

    // Stop scrolling when hovering over the notifications list
    const stopScrollingNotifications = () => {
      isHoveredNotifications = true;
    };

    // Resume scrolling when the mouse leaves the notifications list
    const resumeScrollingNotifications = () => {
      isHoveredNotifications = false;
    };

    // Attach event listeners for hover behavior on exams list
    examsList.addEventListener("mouseover", stopScrollingExams);
    examsList.addEventListener("mouseout", resumeScrollingExams);

    // Attach event listeners for hover behavior on notifications list
    notificationsList.addEventListener("mouseover", stopScrollingNotifications);
    notificationsList.addEventListener(
      "mouseout",
      resumeScrollingNotifications
    );

    // Cleanup function to remove the intervals and event listeners
    return () => {
      clearInterval(examsScrollInterval); // Clear the exams scroll interval
      clearInterval(notificationsScrollInterval); // Clear the notifications scroll interval
      examsList.removeEventListener("mouseover", stopScrollingExams);
      examsList.removeEventListener("mouseout", resumeScrollingExams);
      notificationsList.removeEventListener(
        "mouseover",
        stopScrollingNotifications
      );
      notificationsList.removeEventListener(
        "mouseout",
        resumeScrollingNotifications
      );
    };
  }, [homepageData]);

  return (
    <>
    <Helmet>
        <title>Shyam Institute | No.1 Coaching for SI, SSC & RRB in AP & TS</title>
        <meta 
          name="description" 
          content="Welcome to Shyam Institute — the leading coaching center in Andhra Pradesh & Telangana since 2001. We provide expert guidance for SI, Police Constables, SSC, RRB, and more. Enroll now and achieve your dream career!" 
        />
      </Helmet>

    <div id="homepage">
      <section className="banner-carousel">
        <div className="carousel-container">
          <button className="carousel-button prev" onClick={prevSlide}>
            ❮
          </button>
          {homepageData &&
            homepageData.bannerImages &&
            homepageData.bannerImages.length > 0 && (
              <>
                <img
                  src={homepageData.bannerImages[currentIndex].url}
                  alt={`Banner ${currentIndex + 1}`}
                  className="carousel-image"
                />
                <div className="carousel-pagination">
                  {homepageData.bannerImages.map((_, index) => (
                    <span
                      key={index}
                      className={`pagination-dot ${
                        index === currentIndex ? "active" : ""
                      }`}
                      onClick={() => setCurrentIndex(index)}
                    ></span>
                  ))}
                </div>
              </>
            )}
          <button className="carousel-button next" onClick={nextSlide}>
            ❯
          </button>
        </div>
      </section>

      <section className="services-section">
        <h2>
          <span style={{ color: "#043DEC" }}>Our</span>{" "}
          <span
            style={{
              color: "#F6C300",
              textDecoration: "underline",
              textUnderlineOffset: "10px",
              textDecorationColor: "#1A45AC",
            }}
          >
            Services
          </span>
        </h2>

        <div className="our-services">
          <div className="service-cards-container">
            <button
              className="arrow left"
              onClick={() => scrollServiceLeft(servicesSectionRef)}
            >
              <FontAwesomeIcon icon={faCircleLeft} />
            </button>
            <div className="service-cards-wrapper" ref={servicesSectionRef}>
              {homepageData &&
              homepageData.Services &&
              homepageData.Services.length > 0 ? (
                homepageData.Services.map((service) => (
                  <div key={service.id} className="service-card">
                    <img src={service.logo} alt={service.title} />
                    <h4>{service.title}</h4>
                  </div>
                ))
              ) : (
                <p style={{textAlign:"center",width:"100%"}}>No services available</p>
              )}
            </div>
            <button
              className="arrow right"
              onClick={() => scrollServiceRight(servicesSectionRef)}
            >
              <FontAwesomeIcon icon={faCircleRight} />
            </button>
          </div>
        </div>
      </section>

      {/*Exams and Notifications Section */}
      <section className="exams-notifications-section">
        <div className="exams-notifications-container">
          <h2>
            <span style={{ color: "#043DEC" }} className="exams-heading">
              Exams
            </span>{" "}
            &{" "}
            <span
              style={{
                color: "#F6C300",
                textDecoration: "underline",
                textUnderlineOffset: "10px",
                textDecorationColor: "#1A45AC",
              }}
              className="notifications-heading"
            >
              Notifications
            </span>
          </h2>

          <div className="exams-notifications-content">
            <div className="exams-container">
              <h3>Upcoming Exams</h3>
              <div className="exams-grid-container">
                <div className="exams-grid scrollable">
                  {homepageData &&
                  homepageData.upcomingExams &&
                  homepageData.upcomingExams.length > 0 ? (
                    homepageData.upcomingExams.map((exam) => (
                      <div
                        key={exam.id}
                        className="exam-card"
                        onClick={() => window.open(exam.examLink, "_blank")}
                      >
                        <h4>{exam.examName}</h4>
                        <p>Date: {exam.examDate}</p>
                      </div>
                    ))
                  ) : (
                    <p>No upcoming exams</p>
                  )}
                </div>
              </div>
            </div>

            {/* Notifications */}
            <div className="notificationslist-container">
              <h3>Notifications</h3>
              <div className="notifications-list-container">
                <div className="notifications-list scrollable">
                  {homepageData &&
                  homepageData.notifications &&
                  homepageData.notifications.length > 0 ? (
                    homepageData.notifications.map((notification) => (
                      <div
                        key={notification.id}
                        className="notification-item"
                        onClick={() =>
                          window.open(notification.notificationLink, "_blank")
                        }
                      >
                        <h4>{notification.notificationName}</h4>
                        <p>Date: {notification.notificationDate}</p>
                      </div>
                    ))
                  ) : (
                    <p>No notifications available</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="activities-section">
        <h2>
          <span style={{ color: "#1A45AC" }}>Our Preparation</span>{" "}
          <span
            style={{
              color: "#F6C300",
              textDecoration: "underline",
              textUnderlineOffset: "10px",
              textDecorationColor: "#1A45AC",
            }}
          >
            Activities
          </span>
        </h2>
        <div className="activity-cards-container">
          <div className="activity-cards-wrapper">
            {homepageData &&
            homepageData.Activities &&
            homepageData.Activities.length > 0 ? (
              homepageData.Activities.map((activity) => (
                <div key={activity.id} className="activity-card">
                  <img src={activity.logo} alt={activity.title} />
                  <h4>{activity.title}</h4>
                  <p>{activity.content}</p>
                </div>
              ))
            ) : (
              <p>No activities available</p>
            )}
          </div>
        </div>
      </section>

      {/* Registrations Section */}
      <section ref={ref} className="registrations-section">
        <h2>
          <span style={{ color: "#1A45AC" }}>Our Students</span>{" "}
          <span
            style={{
              color: "#F6C300",
              textDecoration: "underline",
              textUnderlineOffset: "10px",
              textDecorationColor: "#1A45AC",
            }}
          >
            Registrations
          </span>
        </h2>
        <div className="registrations-count-container">
          <div className="registrations-cards-wrapper">
            {homepageData &&
            homepageData.stats &&
            homepageData.stats.length > 0 ? (
              homepageData.stats.map((stats) => (
                <div key={stats.id} className="registrations-card">
                  <img src={stats.logo} alt={stats.id} />
                  <h2
                    className="registration-value"
                    style={{ color: "#1a45ac" }}
                  >
                    {isVisible ? (
                      <CountUp
                        start={0}
                        end={parseInt(stats.value)}
                        duration={2.5}
                        suffix="+"
                      />
                    ) : (
                      <span>{stats.value}+</span>
                    )}
                  </h2>
                  <h3>{stats.id}</h3>
                </div>
              ))
            ) : (
              <p>No registrations available</p>
            )}
          </div>
        </div>
      </section>

      {/* Classes Section */}
      <section className="classes-section">
        <h2>
          <span style={{ color: "#1A45AC" }}>Check Our</span>{" "}
          <span
            style={{
              color: "#F6C300",
              textDecoration: "underline",
              textUnderlineOffset: "10px",
              textDecorationColor: "#1A45AC",
            }}
          >
            Latest videos
          </span>
        </h2>
        <div className="classes-container">
          <div className="class-cards-container">
            <div className="class-cards-wrapper" ref={classesSectionRef}>
              {homepageData &&
              homepageData.classes &&
              homepageData.classes.length > 0 ? (
                homepageData.classes.map((classItem) => {
                  // Convert to YouTube embed URL
                  const embedLink = classItem.videolink
                    .replace("watch?v=", "embed/")
                    .replace("youtu.be/", "youtube.com/embed/")
                    .split("&")[0]; // Remove any query parameters

                  return (
                    <div key={classItem.id} className="class-card">
                      <div className="iframe-container">
                        <iframe
                          src={embedLink}
                          title={classItem.title}
                          frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope"
                          allowFullScreen
                        ></iframe>
                      </div>
                      <h3>{classItem.title}</h3>
                    </div>
                  );
                })
              ) : (
                <p style={{textAlign:"center",width:"100%"}}>No classes available</p>
              )}
            </div>
          </div>
          <div className="classes-navigation">
            <button
              className="classes-nav-btn classes-nav-prev"
              onClick={() => scrollLeft(classesSectionRef)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            <button
              className="classes-nav-btn classes-nav-next"
              onClick={() => scrollRight(classesSectionRef)}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>
        </div>
      </section>

      {/* Feedback Section */}
      <section className="feedback-section">
        <h2>
          <span style={{ color: "#1A45AC" }}>Our Students</span>{" "}
          <span
            style={{
              color: "#F6C300",
              textDecoration: "underline",
              textUnderlineOffset: "10px",
              textDecorationColor: "#1A45AC",
            }}
          >
            Feedback
          </span>
        </h2>
        <div className="feedback-container">
          <div className="feedback-cards-container">
            <div
              className="feedback-cards-wrapper"
              ref={feedbackSectionRef}
              style={{
                display: "flex",
                overflowX: "auto",
                scrollBehavior: "smooth",
                scrollbarWidth: "none",
              }}
            >
              {homepageData?.feedbacks?.length > 0 ? (
                homepageData.feedbacks.map((feedbackItem) => (
                  <div key={feedbackItem.id} className="feedback-card">
                    <div className="quotation-icons">
                      <FontAwesomeIcon
                        icon={faQuoteLeft}
                        className="quote-icon-left"
                      />
                      <FontAwesomeIcon
                        icon={faQuoteRight}
                        className="quote-icon-right"
                      />
                    </div>
                    <p className="feedback-info">{feedbackItem.feedbackText}</p>
                    {/* Feedback Navigation Arrows Inside the Card */}
                    <div className="feedback-arrows">
                      <button
                        className="feedback-nav-btn feedback-nav-prev"
                        onClick={() => scrollFeedbackLeft(feedbackSectionRef)}
                      >
                        <FontAwesomeIcon icon={faChevronLeft} />
                      </button>
                      
                    
                    <img
                      src={feedbackItem.photo}
                      alt={feedbackItem.name}
                      className="feedback-image"
                    />
                    <button
                        className="feedback-nav-btn feedback-nav-next"
                        onClick={() => scrollFeedbackRight(feedbackSectionRef)}
                      >
                        <FontAwesomeIcon icon={faChevronRight} />
                      </button>
                      </div>
                    <h3 className="feedback-name">{feedbackItem.name}</h3>
                    <p className="feedback-position">
                      {feedbackItem.achievement}
                    </p>

                    
                  </div>
                ))
              ) : (
                <p style={{textAlign:"center",width:"100%"}}>No feedback available</p>
              )}
            </div>
          </div>
        </div>
      </section>

      {/* App Section */}
      <section className="playapp-section">
        <div className="playapp-container">
          <div className="playapp-container-left">
            <div>
              <h2 style={{ color: "#1A45AC", fontWeight: "400" }}>
                Get Shyam Institute App On
              </h2>
              <h2 style={{ color: "#F6C300", fontWeight: "700" }}>
                Your Phone & Get Access
              </h2>
            </div>
            <div className="playapp-options">
              <div className="playapp-option-buttons">
                <p>Interactive Lessons</p>
              </div>
              <div className="playapp-option-buttons">
                <p>Tests</p>
              </div>
              <div className="playapp-option-buttons">
                <p>Videos</p>
              </div>
            </div>
            <div className="play-container">
              <a
                href="https://play.google.com/store/apps/details?id=co.kevin.vpupu"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={play} alt="playstore" className="playstore-image" />
              </a>
              <a
                href="https://apps.apple.com/in/app/classplus/id1324522260"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={Appstore}
                  alt="playstore"
                  className="playstore-image"
                />
              </a>
            </div>
          </div>
          <div className="playapp-container-right">
            <img src={mobile} alt="mobile-using" className="mobile-image" />
          </div>
        </div>
      </section>

      <ScrollToTop />

      {showPopup && popupData.length > 0 ? (
        <div className="popup-overlay">
          <div className="popup-container">
            <FontAwesomeIcon
              icon={faCircleXmark}
              className="popup-closeIcon"
              onClick={handleClosePopup}
            />
            <a
              href={popupData[currentPopupIndex].link || "#"}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={popupData[currentPopupIndex].image}
                alt="Popup"
                className="popup-image"
              />
            </a>
          </div>
        </div>
      ) : null}
    </div>
    </>
  );
};

export default HomePage;
