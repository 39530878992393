import React, { useState, useEffect } from "react";
import "./InformationCenter.css";
import BASE_URL from "../../components/link/link";

const InformationCenter = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [bannerImage, setBannerImage] = useState("");

  const API_URL = `${BASE_URL}/batches/get-upcoming-batches`;
  const BANNER_API_URL = `${BASE_URL}/banner/upcoming-batches-banner`;

  // Fetch data from the API
  useEffect(() => {

    const fetchBanner = async () => {
      try {
        const response = await fetch(BANNER_API_URL);
        if (!response.ok) {
          throw new Error("Failed to fetch banner");
        }
        const bannerData = await response.json();
        setBannerImage(bannerData[0].imageUrl);
      } catch (error) {
        console.error("Error fetching banner:", error);
      }
    };

    
    const fetchData = async () => {
      try {
        setLoading(true); // Set loading state
        const response = await fetch(API_URL);
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const result = await response.json();
        setData(result.batches); // Update state with fetched data
      } catch (error) {
        setError(error.message); // Handle errors
      } finally {
        setLoading(false); // Reset loading state
      }
    };

    fetchBanner();
    fetchData();
  }, []);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  const filteredData = data.filter(
    (item) =>
      item.Course.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.Branch.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="main-container">
      <div className="informationcenter-container">
        {bannerImage && (
          <img
            src={bannerImage}
            alt="Upcoming Batches Banner"
            className="informationcenter-image"
          />
        )}
      </div>

      <div className="information-search-section">
        <input
          type="text"
          className="information-search-bar"
          placeholder="Search by branch or course..."
          value={searchTerm}
          onChange={handleSearch}
        />
      </div>

      <div className="table-container">
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : (
          <>
            <table className="table">
              <thead>
                <tr>
                  <th>Branch & Course</th>
                  <th>Start Date</th>
                  <th>Contact</th>
                </tr>
              </thead>
              <tbody>
                {filteredData
                  .slice(0, isExpanded ? filteredData.length : 3)
                  .map((item, index) => (
                    <tr key={item._id}>
                      <td>{`${item.Branch} - ${item.Course}`}</td>
                      <td>{new Date(item.StartDate).toLocaleDateString()}</td>
                      <td>{item.ContactNumber}</td>
                    </tr>
                  ))}
              </tbody>
            </table>

            <button className="view-more-button" onClick={toggleExpand}>
              {isExpanded ? "View Less" : "View More"}
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default InformationCenter;
