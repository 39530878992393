import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import {
//   faInstagram,
//   faYoutube,
//   faWhatsapp,
//   faTwitter,
//   faFacebook,
// } from "@fortawesome/free-brands-svg-icons";
import { faMessage } from "@fortawesome/free-regular-svg-icons";
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons";
// import { SocialIcon } from 'react-social-icons';
import instagram from "../../assets/icons/insta.svg";
import youtube from "../../assets/icons/yt.svg";
import whatsapp from "../../assets/icons/whatsapp.svg";
import twitter from "../../assets/icons/twitter2.svg";
import facebook from "../../assets/icons/fb.svg";
import telegram from "../../assets/icons/tele.svg"
import { useNavigate } from "react-router-dom";


import "./Footer.css";

const Footer = () => {
  const [isPopUpOpen, setIsPopUpOpen] = useState(false);
  const [popUpContent, setPopUpContent] = useState("");
  const [isCheckBoxChecked, setIsCheckBoxChecked] = useState(false);
  const [isPrivacyChecked, setIsPrivacyChecked] = useState(false);
  const navigate = useNavigate();


  const openPopUp = (page) => {
    if (page === "terms-and-conditions") {
      navigate("/terms-and-conditions");
    } else if (page === "privacy-and-policy") {
      navigate("/privacy-and-policy");
    } else if (page === "cancellation-refund-policy") {
      navigate("/cancellation-refund-policy");
    }
  };

  return (
    <>
      <div className="footer-top">
        <p>Call For Any Assistance</p>
        <p>
          <FontAwesomeIcon
            icon={faPhone}
            style={{ marginRight: "10px", color: "#ffffff" }} // Yellow phone icon
          />
          +91 9848 1362 54 | +91 9848 2562 54
        </p>

        <p>
          <FontAwesomeIcon
            icon={faEnvelope}
            style={{ marginRight: "10px", color: "#ffffff" }}
          />
          <a style={{ textDecoration: "none", color: "white" }} href="mailto:info@shyaminstitute.com">info@shyaminstitute.com</a>
        </p>
      </div>

      <footer className="footer">
        <div className="footer-section">
          <h2>
            <span style={{ color: "#1A45AC" }}>Shyam</span>{" "}
            <span
              style={{
                color: "#F6C300",

              }}
            >
              Institute
            </span>
          </h2>
          <p className="footer-address">Your Gateway to Knowledge</p>
          <p className="footer-address">
            D.no 68-10-20/G, Nancy St, Vidhyuth Nagar, SBI Off Colony,
            <br />
            Kakinada, Andhra Pradesh 533003
            <br />
            Phone: +91 98482 56254
            <br />
            Email: info@shyaminstitute.com
          </p>
          <div className="footer-social">
            <h3>Follow Us</h3>
            <div className="social-icons">
              <a
                href="https://www.instagram.com/shyaminstitute_kakinada/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="Instagram" className="social-icon" />
              </a>
              <a
                href="https://www.youtube.com/@shyaminstitute-kakinada"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={youtube} alt="YouTube" className="social-icon" />
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=9848136254"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={whatsapp} alt="WhatsApp" className="social-icon" />
              </a>
              <a
                href="https://x.com/shyam_inst2001"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={twitter} alt="Twitter" className="social-icon" />
              </a>
              <a
                href="https://www.facebook.com/people/SHYAM-Institute/100064113000035/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="Facebook" className="social-icon" />
              </a>
              <a
                href="https://t.me/shyaminstitutekakinada2001"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={telegram} alt="Telegram" className="social-icon" />
              </a>
            </div>
          </div>
        </div>
        <div className="footer-section">
          <h3>Quick Links</h3>
          <ul>
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/mission&vision">About Us</a>
            </li>
            <li>
              <a href="/courses">Courses</a>
            </li>
            <li>
              <a href="/exam-calendar">Exams</a>
            </li>
            <li>
              <a href="/materials">Materials</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Our Services</h3>
          <ul>
            <li>
              <a href="/">SI & PC Coaching</a>
            </li>
            <li>
              <a href="/">APPSC Groups Coaching</a>
            </li>
            <li>
              <a href="/">AP DSC Coaching</a>
            </li>
            <li>
              <a href="/">RRB Coaching</a>
            </li>
            <li>
              <a href="/">Staff Selection Commission Coaching(SSC)</a>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h3>Contact Us</h3>
          <ul>
            <p>
              <FontAwesomeIcon
                icon={faMessage}
                style={{ marginRight: "10px", color: "#f6c300" }}
              />
              We’d love to hear from you!
            </p>
            <p>
              <a href="/">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  style={{ marginRight: "10px", color: "#f6c300" }}
                />
                Email:{" "}
                <a href="mailto:info@shyaminstitute.com">
                  info@shyaminstitute.com
                </a>
              </a>
            </p>
            <li className="phone">
              <a href="/" className="phone-content">
                <FontAwesomeIcon
                  icon={faPhone}
                  style={{ marginRight: "10px", color: "#f6c300" }} // Yellow phone icon
                />
                Phone:{" "}
                <span className="phone-numbers">
                  <span>+91 9848 1362 54</span>
                  <span>+91 9848 2562 54</span>
                </span>
              </a>
            </li>
          </ul>
        </div>
      </footer>
      {/* <div className="footer-terms-privacy">
        <ul className="terms-privacy">
          <li>
            <a href="/terms-and-conditions">Terms & Conditions</a>
          </li>
          <li>
            <a href="/privacy-policy">Privacy Policy</a>
          </li>
        </ul>
      </div> */}
      <div className="footer-bottom">
        <p>
          &copy; {new Date().getFullYear()} Shyam Institute. All rights reserved.
        </p>
        <ul className="terms-privacy">
          <li>
            <a onClick={() => openPopUp("terms-and-conditions")}>
              Terms & Conditions
            </a>
          </li>
          <li>
            <a onClick={() => openPopUp("privacy-and-policy")}>
              Privacy Policy
            </a>
          </li>
          <li>
            <a onClick={() => openPopUp("cancellation-refund-policy")}>
            Cancellation & Refund Policy
            </a>
          </li>
        </ul>
      </div>

      {isPopUpOpen && (
        <div className="terms-privacy-pop-up">
          <div className="pop-up-content">
            <div className="pop-up-text">
              <p>{popUpContent}</p>
            </div>
            <div className="pop-up-checkbox">
              {popUpContent.includes("*Terms and Conditions*") && (
                <>
                  <input
                    type="checkbox"
                    id="acceptTerms"
                    checked={isCheckBoxChecked}
                    onChange={(e) => setIsCheckBoxChecked(e.target.checked)}
                  />
                  <label htmlFor="acceptTerms">
                    By enrolling in the training program, you confirm that you have
                    read, understood, and agree to abide by these Terms and Conditions.
                  </label>
                </>
              )}
              {popUpContent.includes("*Privacy Policy*") && (
                <>
                  <input
                    type="checkbox"
                    id="acceptPrivacy"
                    checked={isPrivacyChecked}
                    onChange={(e) => setIsPrivacyChecked(e.target.checked)}
                  />
                  <label htmlFor="acceptPrivacy">
                    By enrolling in the training program, you confirm that you have
                    read, understood, and agree to the Privacy Policy.
                  </label>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Footer;
