import React, { useState, useEffect } from "react";
import "./Gallery.css"; // Assume the CSS styles are written here
import achievementsimg from '../../assets/images/achivementsImg.jpg'
import BASE_URL from "../../components/link/link";
import { Helmet } from 'react-helmet';


const App = () => {
  // State variables
  const [categories, setCategories] = useState({});
  const [achievements, setAchievements] = useState([]);
  const [currentCategory, setCurrentCategory] = useState("all");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage());
  const [loading, setLoading] = useState(true);

  // Function to determine items per page based on screen width
  function getItemsPerPage() {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 480) return 1; // Mobile view: 1 image per page
    if (screenWidth <= 768 || screenWidth <=1024 || screenWidth <=1439) return 2; // Tablet view: 3 images per page
    return 4; // Default: 4 images per page
  }

  // Fetch data from API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/portfolio/all`
        );
        const data = await response.json();

        // Organize data by categories
        const organizedData = data.reduce(
          (acc, item) => {
            const category = item.category.toLowerCase();
            if (category === "achievements") {
              acc.achievements.push(item.imageUrl); // Add achievements separately
            } else {
              const portfolioCategory =
                category === "lab" || category === "training"
                  ? "lab/training"
                  : category;
              if (!acc.portfolio[portfolioCategory]) {
                acc.portfolio[portfolioCategory] = [];
              }
              acc.portfolio[portfolioCategory].push(item.imageUrl);
            }
            return acc;
          },
          { portfolio: {}, achievements: [] }
        );

        // Add 'all' category for portfolio
        organizedData.portfolio.all = Object.values(
          organizedData.portfolio
        ).flat();

        setCategories(organizedData.portfolio);
        setAchievements(organizedData.achievements);
        setLoading(false);
      } catch (error) {
        console.error("Failed to fetch data:", error);
      }
    };

    fetchData();
  }, []);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => {
      const newItemsPerPage = getItemsPerPage();
      if (newItemsPerPage !== itemsPerPage) {
        setItemsPerPage(newItemsPerPage);
        setCurrentPage(1); // Reset to first page on layout change
      }
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [itemsPerPage]);

  // Get current images for the selected category
  const currentImages = categories[currentCategory] || [];
  const totalPages = Math.ceil(currentImages.length / itemsPerPage);
  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const imagesToShow = currentImages.slice(startIndex, endIndex);

  // Render images for the current page
  const renderImages = () => {
    return imagesToShow.map((src, index) => (
      <img key={index} src={src} alt="Portfolio" />
    ));
  };

  // Render achievements images
  const renderAchievements = () => {
    return achievements.map((src, index) => (
      <img key={index} src={src} alt="Achievement" />
    ));
  };

  return (
    <>
    <Helmet>
        <title>Shyam Institute Gallery | Moments of Learning & Success</title>
        <meta 
          name="description" 
          content="Explore the Shyam Institute gallery featuring classroom sessions, student achievements, and more. Witness the journey of aspiring civil servants in action!" 
        />
      </Helmet>
      {/* Portfolio Section */}
      <h1 className="title">
        <span>Our </span>
        <span>Portfolio</span>
      </h1>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <div className="gallery-entire">
          {/* Left Container */}
          <div className="gallery-left-container">
            <div className="gallery-inner">
              <div className="gallery-buttons">
                {Object.keys(categories).map((category) => (
                  <button
                    key={category}
                    className={currentCategory === category ? "active" : ""}
                    onClick={() => {
                      setCurrentCategory(category);
                      setCurrentPage(1); // Reset to the first page
                    }}
                  >
                    {category.toUpperCase()}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Right Container */}
          <div className="gallery-right-container">
            <div id="image-container">{renderImages()}</div>
            <div className="gallery-pagination">
              <button
                id="prev"
                disabled={currentPage === 1}
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              >
                &larr;
              </button>
              <span id="page-info">
                {currentPage}/{totalPages}
              </span>
              <button
                id="next"
                disabled={currentPage === totalPages}
                onClick={() =>
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                }
              >
                &rarr;
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Achievements Section */}
      <h1 className="gallery-title">
        <span>Our </span>
        <span>Achievements</span>
      </h1>
      <div className="gallery-subtitle-container">
      <h1 className="gallery-subtitle">Our Students Scored All Time Record</h1>
      <span><img src={achievementsimg} className="gallery-achievementsimage"></img></span>
      </div>
      <div className="Gallery-achievements">{renderAchievements()}</div>
    </>
  );
};

export default App;
