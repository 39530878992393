import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms and Conditions</h1>

      <section>
        <h2>1. Introduction</h2>
        <p>
          Welcome to Shyam. By enrolling in any training programs or courses offered by the Institute, you agree to comply with and be bound by the following Terms and Conditions. Please read them carefully before proceeding with your registration.
        </p>
      </section>

      <section>
        <h2>2. Eligibility</h2>
        <ul>
          <li>To enroll in the courses, candidates must meet the eligibility criteria specified for each program.</li>
          <li>The Institute reserves the right to deny admission to any applicant who does not meet these eligibility requirements.</li>
        </ul>
      </section>

      <section>
        <h2>3. Registration and Enrollment</h2>
        <ul>
          <li>All prospective trainees must complete the registration process and pay the required fees before commencing any training program.</li>
          <li>The Institute may refuse or cancel admission at any point if it finds that the provided information is inaccurate, incomplete, or false.</li>
        </ul>
      </section>

      <section>
        <h2>4. Fees and Payment</h2>
        <ul>
          <li>The course fees must be paid in full at the time of registration unless a payment installment plan has been agreed upon.</li>
          <li>Payments should be made through the specified modes of payment (bank transfer, cheque, etc.).</li>
          <li>No refunds will be issued once the program has commenced, except under exceptional circumstances as determined by the Institute.</li>
        </ul>
      </section>

      <section>
        <h2>5. Code of Conduct</h2>
        <ul>
          <li>All trainees are expected to maintain discipline, professionalism, and respect for fellow trainees, instructors, and staff members at all times.</li>
          <li>Trainees must follow the instructions given by the trainers and abide by the Institute’s regulations and policies.</li>
          <li>Any behavior deemed disruptive or disrespectful may result in disciplinary action, including expulsion from the course.</li>
        </ul>
      </section>

      <section>
        <h2>6. Physical Fitness Requirements</h2>
        <p>
          As a police training institute, physical fitness is a crucial component of the training. The Institute reserves the right to assess a trainee’s physical fitness at any point during the course and may request medical documentation if necessary.
        </p>
      </section>

      <section>
        <h2>7. Attendance Policy</h2>
        <p>
          Full attendance is expected for all training sessions. Absences must be notified in advance. Excessive absence may result in disqualification from completing the program.
        </p>
      </section>

      <section>
        <h2>8. Course Materials and Equipment</h2>
        <p>
          The Institute provides certain materials for the training program. Any materials provided are for personal use and may not be shared or redistributed without prior consent.
        </p>
      </section>

      <section>
        <h2>9. Intellectual Property</h2>
        <p>
          All course materials, training manuals, and intellectual property remain the property of Shyam Institute. Trainees are prohibited from reproducing or using any materials for commercial purposes without written consent.
        </p>
      </section>

      <section>
        <h2>10. Confidentiality</h2>
        <p>
          During the training program, you may be exposed to confidential information. You agree to maintain the confidentiality of all shared information. Breaching confidentiality may result in legal consequences and dismissal.
        </p>
      </section>

      <section>
        <h2>11. Health and Safety</h2>
        <p>
          The Institute is committed to ensuring a safe training environment. Trainees must adhere to all safety guidelines and notify the Institute of any medical conditions before commencing training.
        </p>
      </section>

      <section>
        <h2>12. Disclaimers and Limitation of Liability</h2>
        <p>
          Shyam Institute is not responsible for any injuries or damages incurred during training. Trainees participate at their own risk.
        </p>
      </section>

      <section>
        <h2>13. Termination of Enrollment</h2>
        <p>
          The Institute reserves the right to terminate a trainee’s enrollment if they fail to adhere to the rules and standards of the program.
        </p>
      </section>

      <section>
        <h2>14. Changes to the Terms and Conditions</h2>
        <p>
          The Institute reserves the right to modify these Terms and Conditions at any time. The revised terms will apply to future enrollments.
        </p>
      </section>

      <section>
        <h2>15. Governing Law</h2>
        <p>
          These Terms and Conditions are governed by the laws of [Country/State]. Any disputes will be resolved in the courts of [Location].
        </p>
      </section>

      <p className="confirmation">
        By enrolling in the training program, you confirm that you have read, understood, and agree to abide by these Terms and Conditions.
      </p>
    </div>
  );
};

export default TermsAndConditions;
