import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faWhatsapp, faFacebookF, faGooglePlay, faYoutube, faXTwitter, faTelegram } from '@fortawesome/free-brands-svg-icons';
import './StickyIcons.css';  // Import your CSS file

const StickyIcons = () => {
  return (
    <div className="sticky-icon">
      <a href="https://api.whatsapp.com/send?phone=9848136254" className="Whatsapp" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className='icon-sticky' icon={faWhatsapp} style={{ marginRight: '20px', marginLeft: '5px' }} /> WhatsApp
      </a>
      <a href="https://www.instagram.com/shyaminstitute_kakinada/" className="Instagram" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className='icon-sticky' icon={faInstagram} style={{ marginRight: '20px', marginLeft: '5px' }} /> Instagram
      </a>
      <a href="https://www.facebook.com/people/SHYAM-Institute/100064113000035/" className="Facebook" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className='icon-sticky' icon={faFacebookF} style={{ marginRight: '20px', marginLeft: '5px' }} /> Facebook
      </a>
      <a href="https://play.google.com/store/apps/details?id=co.kevin.vpupu" className="Google" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className='icon-sticky' icon={faGooglePlay} style={{ marginRight: '20px', marginLeft: '5px' }} /> PlayStore
      </a>
      <a href="https://www.youtube.com/@shyaminstitute-kakinada" className="Youtube" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className='icon-sticky' icon={faYoutube} style={{ marginRight: '20px', marginLeft: '5px' }} /> Youtube
      </a>
      <a href="https://t.me/shyaminstitutekakinada2001" className="Telegram" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon className='icon-sticky' icon={faTelegram} style={{ marginRight: '20px', marginLeft: '5px' }} /> Telegram
      </a>
    </div>
  );
};

export default StickyIcons;
