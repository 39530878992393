import { BrowserRouter, Routes, Route, Navigate  } from "react-router-dom";
import "./App.css";
import React from "react";
import Layout from "./pages/layout/Layout";
// import Signup from "./pages/signup";
import HomePage from "./pages/homePage/HomePage";
import ChairmanBanner from "./pages/ChairmanMessagePage/ChairmanMessage";
import MissionVision1 from "./pages/MissionVision/MissionVision";
import ShyamMessage from "./pages/ShyamMethodologyPage/ShyamMessage";
import Course from "./pages/courses/course";
import ExamCalendar from "./pages/ExamCalenderPage/ExamCalender";
import ExamResults from "./pages/ExamResultsPage/ExamResults";
import PreviousPapers from "./pages/PreviousPapersPage/PreviousPapers";
import NotificationsCenter from "./pages/NotificationPage/NotificationCenter";
import Contact from "./pages/contactPage/Contact";
import SuccessStories from "./pages/successstories/SuccessStories";
import Material from "./pages/materials/Material";
import Subscription from "./pages/subscription/Subscription";
import Gallery from "./pages/gallery/Gallery";
import InformationCenter from "./pages/informationcenter/InformationCenter";
import Login from "./pages/login/login";
import Signup from "./pages/login/signup";
import OurCenters from "./pages/OurCenters/OurCenters";
import Profile from "./pages/Userprofile/Userdata";
import ResetPassword from "./pages/Resetpassword/Resetpassword";
import ScrollUp from "./components/scrollUp/scrollUp";
import TermsAndConditions from "./pages/termsAndConditions/TermsAndConditions";
import Privacy from "./pages/PrivacyAndPolicy/PrivacayAndPolicy";
import RefundPolicy from "./pages/refundPolicy/RefundPolicy";
import Cart from "./pages/cart/Cart";
import Checkout from "./pages/checkout/Checkout";
import AllBlogs from "./pages/allBlogs/AllBlogs";
import SingleBlog from "./pages/singleBlog/SingleBlog";

// import Login from "./pages/login";

function App() {

  return (
    <div className="App">
      <BrowserRouter>
      <ScrollUp/>
        <Routes>
          <Route element={<Layout />}>
            <Route path="/login" element={<Login/>} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/" element={<HomePage />} />
            <Route path="/chairman-message" element={<ChairmanBanner />} />
            <Route path="/mission&vision" element={<MissionVision1 />} />
            <Route path="/shyam-methodology" element={<ShyamMessage />} />
            <Route path="/courses" element={<Course />} />
            <Route path="/exam-calendar" element={<ExamCalendar />} />
            <Route path="/results" element={<ExamResults />} />
            <Route path="/previous-papers" element={<PreviousPapers />} />
            <Route path="/notification-center" element={<NotificationsCenter />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/success-stories" element={<SuccessStories />} />
            {/* <Route path="/information-center" element={<InformationCenter />} /> */}
            <Route path="/upcoming-batches" element={<InformationCenter />} />
            <Route path="/our-centers" element={<OurCenters />} />
            <Route path="/materials" element={<Material />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/gallery" element={<Gallery />} />
            <Route path="/subscription" element={<Subscription />} />
            <Route path="/profile" element={<Profile />} />
            <Route path="/reset-password/:token" element={<ResetPassword />} />
            <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
            <Route path="/privacy-and-policy" element={<Privacy />} />
            <Route path="/cancellation-refund-policy" element={<RefundPolicy />} />
            <Route path="/blog" element={<AllBlogs />} />
            <Route path="/blog/:title" element={<SingleBlog />} />




            
            <Route path="*" element={<Navigate to="/" />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
