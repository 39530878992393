import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Loginbutton.css";
import BASE_URL from "../link/link";

const Loginbutton = ({
  onLogin,
  onLogout,
  isLoggedIn,
  handleLinkClick,
  activeLink,
  toggleDropdown,
  dropdownVisible,
}) => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState("");

  useEffect(() => {
    // Check if the token exists in localStorage when the component mounts
    const token = localStorage.getItem("token");
    if (token) {
      onLogin(); // Inform parent about login state change

      const fetchUserName = async () => {
        try {
          const response = await fetch(`${BASE_URL}/user/me`, {
            method: "GET",
            headers: {
              authorization: `Bearer ${token}`,
            },
          });

          if (!response.ok) {
            if (response.status === 401) {
              // Token expired or invalid
              localStorage.removeItem("token");
              onLogout();
              navigate("/login");
              return;
            }
            throw new Error("Failed to fetch user data.");
          }

          const data = await response.json();
          setUserName(data.user.name); // Set the user's name
        } catch (err) {
          console.error("Error fetching user data:", err);
          // Handle other errors if necessary
        }
      };

      fetchUserName();
    }
  }, [onLogin, onLogout, navigate]);

  const handleLogin = () => {
    if (!isLoggedIn) {
      handleLinkClick("login");
      navigate("/login");
    }
  };

  const handleLogout = () => {
    localStorage.removeItem("token");
    onLogout();
    handleLinkClick("");
    navigate("/");
  };

  const navigateToProfile = () => {
    handleLinkClick("profile");
    navigate("/profile");
  };

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "");

  return (
    <div className="login-container">
      {isLoggedIn ? (
        <div className={`profile-dropdown-container ${dropdownVisible ? "dropdown-active" : ""}`}>
          <button
            className="profile-dropdown-button"
            onClick={(e) => toggleDropdown(e, "profile")}
          >
           {userName ? getInitial(userName) : "?"}
          </button>
          <ul
            className={`profile-dropdown-menu ${dropdownVisible ? "show" : ""}`}
          >
            <li>
              <button onClick={navigateToProfile}>View Profile</button>
            </li>
            <li>
              <button className="logout" onClick={handleLogout}>Logout</button>
            </li>
          </ul>
        </div>
      ) : (
        <button
          onClick={handleLogin}
          className={`login-signup-button ${
            activeLink === "login" ? "active" : ""
          }`}
        >
          Login
        </button>
      )}
    </div>
  );
};

export default Loginbutton;
