import React, { useState, useEffect } from "react";
import "./Checkout.css";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import BASE_URL from "../../components/link/link";
import useStudentId from "../../components/link/authutils";

const Checkout = () => {
  const navigate = useNavigate();
  const { studentId, isLoading } = useStudentId();
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Address state
  const [address, setAddress] = useState({
    name: "",
    phone: "",
    addressLine: "",
    city: "",
    state: "",
    postalCode: "",
  });

  useEffect(() => {
    const fetchItems = async () => {
      try {
        const storedBuyNowItem = JSON.parse(localStorage.getItem("buyNowItem"));
        // console.log("Stored Buy Now Item:", storedBuyNowItem);

        if (storedBuyNowItem) {
          setItems([storedBuyNowItem]);
          // console.log("Using Buy Now Item:", [storedBuyNowItem]);
        } else if (studentId) {
          // console.log(`Fetching cart for studentId: ${studentId}`);
          const response = await fetch(`${BASE_URL}/cart/getCart/${studentId}`);

          if (!response.ok) {
            throw new Error("Failed to fetch cart items");
          }

          const data = await response.json();
          // console.log("Fetched Cart Data:", data);

          setItems(data.cart.items || []);
        }
      } catch (err) {
        console.error("Error fetching items:", err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchItems();
  }, [studentId]);

  // Handle address changes
  const handleAddressChange = (e) => {
    const { name, value } = e.target;
    if (name === "name" && (!/^[A-Za-z\s]*$/.test(value) || value.length > 50))
      return;
    if (name === "phone" && (!/^\d*$/.test(value) || value.length > 10)) return;

    setAddress((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Calculate total price
  const amount = items.reduce(
    (total, item) => total + item.price * (item.quantity || 1),
    0
  );
  // console.log("Final Items in Checkout:", items);
  // console.log("Total Amount:", amount);

  // Handle cancel
  const handleCancel = async () => {
    try {
      localStorage.removeItem("buyNowItem");
      toast.info("Order cancelled", {
        position: "top-center",
        autoClose: 1500,
      });
      setTimeout(() => navigate("/materials"), 1500);
    } catch (err) {
      toast.error("Error cancelling order. Please try again.");
    }
  };


  const saveOrder = async (paymentDetails) => {
    try {
      // Log the data being sent
      const orderData = {
        studentId,
        Items: items.map(item => ({
          productId: item._id || item.productId,
          name: item.name,
          price: item.price,
          quantity: item.quantity || 1,
          imageUrl: item.banner || item.imageUrl,
          subtopic: item.subtopic
        })),
        totalAmount: amount,
        Address: {
          name: address.name,
          phone: address.phone,
          addressLine: address.addressLine,
          city: address.city,
          state: address.state,
          postalCode: address.postalCode
        },
        paymentId: paymentDetails.razorpay_payment_id,
        orderId: paymentDetails.razorpay_order_id
      };

      // console.log('Attempting to save order with data:', orderData);

      // Step 1: Save the order
      const response = await fetch(`${BASE_URL}/order/saveOrder`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(orderData)
      });

      // console.log('Save order response status:', response.status);

      if (!response.ok) {
        const errorData = await response.text();
        console.error('Server error response:', errorData);
        throw new Error(`Failed to save order: ${errorData}`);
      }

      const savedOrder = await response.json();
      // console.log('Order saved successfully:', savedOrder);

      // Step 2: Clear the cart if the user purchased cart items
      const isBuyNowItem = localStorage.getItem("buyNowItem");
      if (!isBuyNowItem) {
        // console.log("Clearing cart for studentId:", studentId);
        const clearCartResponse = await fetch(`${BASE_URL}/cart/${studentId}/clear`, {
          method: 'DELETE',
        });

        if (!clearCartResponse.ok) {
          const errorData = await clearCartResponse.text();
          console.error('Failed to clear cart:', errorData);
          throw new Error(`Failed to clear cart: ${errorData}`);
        }

        const clearCartData = await clearCartResponse.json();
        // console.log('Cart cleared successfully:', clearCartData);
      }

      // Step 3: Clear the buy now item from localStorage (if it exists)
      localStorage.removeItem("buyNowItem");

      toast.success('Order placed successfully!');
      // Navigate to a success page or order confirmation
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      console.error('Error saving order:', error);
      console.error('Error details:', {
        message: error.message,
        stack: error.stack
      });

      toast.error(`Failed to save order: ${error.message}. Please contact support.`);
      throw error; // Re-throw to be handled by the caller
    }
  };

  // Handle payment
  const handlePay = async (amount) => {
    // console.log("Received amount:", amount, typeof amount);

    const missingFields = Object.entries(address)
      .filter(([_, value]) => !value)
      .map(([key]) => key.charAt(0).toUpperCase() + key.slice(1));

    if (missingFields.length > 0) {
      toast.error(`Please fill out: ${missingFields.join(", ")}`, {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      return;
    }

    if (typeof amount !== "number") {
      console.error("❌ ERROR: Invalid amount", amount);
      alert("Invalid payment amount");
      return;
    }

    try {
      // console.log("Sending request to create Razorpay order...");
      // Step 1: Create an order on the backend
      const res = await fetch(`${BASE_URL}/payment/order`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({ amount: Number(amount) * 100 }), // Send amount to backend
      });

      const data = await res.json();
      // console.log("Order Response Data:", data);

      // ✅ Check if API response is valid
      if (!data || !data.data) {
        console.error("❌ ERROR: Invalid Order Data", data);
        throw new Error("Failed to create payment order");
      }

      // console.log("Proceeding to Razorpay Checkout...");

      // Step 2: Proceed to Razorpay payment using the order details
      await handlePayVerify(data.data);
    } catch (error) {
      // console.error("❌ Payment Error:", error.message);
      alert("Something went wrong. Please try again.");
      window.location.reload();
    }
  };

  const handlePayVerify = (data) => {
    return new Promise((resolve, reject) => {
      const options = {
        key: "rzp_live_sFc64UI72d3NUw",
        amount: data.amount,
        currency: data.currency,
        name: "Shyam Institute",
        description: "Test Mode",
        order_id: data.id,
        handler: async (response) => {
          // console.log("Razorpay Response:", response);

          try {
            // Step 3: Verify the payment with the backend
            const res = await fetch(`${BASE_URL}/payment/verify`, {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              }),
            });

            const verifyData = await res.json();

            if (verifyData.message) {
              // console.log("Verification Success:", verifyData.message);
              toast.success(verifyData.message);
              // Save the order after successful payment
              try {
                await saveOrder(response);
                alert("Payment Successful and Order Saved ✅");
                resolve();
              } catch (saveError) {
                console.error("Order save failed:", saveError);
                alert("Payment Successful but Order Save Failed ❌");
                reject(saveError);
              }
            } else {
              throw new Error("Payment verification failed");
            }
          } catch (error) {
            console.error("Verification Error:", error);
            alert("Payment Failed ❌");
            reject(error);
          }
        },
        theme: {
          color: "#5f63b8",
        },
      };

      // Step 4: Open Razorpay checkout
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    });
  };

  if (loading) return <div className="address-container">Loading...</div>;
  if (error) return <div className="address-container">Error: {error}</div>;
  if (items.length === 0)
    return <div className="address-container">No items to checkout</div>;

  return (
    <div className="address-container">
      <h1>Shipping Address</h1>
      <div className="address-form">
        <form autoComplete="off">
          <div className="form-group">
            <label>
              Name<span className="required">*</span>
            </label>
            <input
              type="text"
              name="name"
              value={address.name}
              onChange={handleAddressChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label>
              Phone Number<span className="required">*</span>
            </label>
            <input
              type="tel"
              name="phone"
              value={address.phone}
              onChange={handleAddressChange}
              maxLength={10}
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label>
              Address Line<span className="required">*</span>
            </label>
            <input
              type="text"
              name="addressLine"
              value={address.addressLine}
              onChange={handleAddressChange}
              maxLength={150}
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label>
              City<span className="required">*</span>
            </label>
            <input
              type="text"
              name="city"
              value={address.city}
              onChange={handleAddressChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label>
              State<span className="required">*</span>
            </label>
            <input
              type="text"
              name="state"
              value={address.state}
              onChange={handleAddressChange}
              required
              autoComplete="off"
            />
          </div>
          <div className="form-group">
            <label>
              Postal Code<span className="required">*</span>
            </label>
            <input
              type="text"
              name="postalCode"
              value={address.postalCode}
              onChange={handleAddressChange}
              required
              autoComplete="off"
            />
          </div>
        </form>
      </div>
      {/* Order Summary */}
      <div className="order-summary">
        <h2>Order Summary</h2>
        {items.map((item) => (
            <div key={item._id || item.productId} className="order-item">
              <img
                src={item.banner || item.imageUrl}
                alt={item.name}
                className="order-item-image"
              />
              <div>
                <h3>{item.name}</h3>
                <div>
                  <p>Quantity: {item.quantity || 1}</p>
                  <p>Price: ₹{item.price}</p>
                </div>
              </div>
            </div>
        ))}
        <div className="total-amount-check">
          <h3>Total Amount: ₹{amount}</h3>
          <h4>(Included Courier Charges at Price Tag)</h4>
        </div>
      </div>
      <div className="checkout-buttons">
        <button className="cancel-button" onClick={handleCancel}>
          Cancel Order
        </button>
        <button className="pay-button" onClick={() => handlePay(amount)}>
          Pay Now
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Checkout;