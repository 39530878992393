import React, { useEffect, useState } from "react";
import "./ChairmanMessage.css";
import BASE_URL from "../../components/link/link";
import { Helmet } from "react-helmet";

const ChairmanBanner = () => {
  const [data, setData] = useState(null);

  // Fetch data from the backend
  useEffect(() => {
    fetch(`${BASE_URL}/chairmanmessage/all`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.length > 0) {
          setData(data[0]); // Assuming you only need the first object
        }
      })
      .catch((error) => console.error("Error fetching chairman data:", error));
  }, []);

  // If data is not yet loaded, show a loading message
  if (!data) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <>
    <Helmet>
        <title>About Shyam Institute: Leading Coaching Center in AP & TS Since 2001</title>
        <meta 
          name="description" 
          content="Learn about Shyam Institute's journey since 2001 as the No.1 coaching institute in Andhra Pradesh and Telangana. Discover our mission, achievements, and commitment to student success." 
        />
      </Helmet>
      <div className="chairman-home-container">
        {/* Background Image */}
        <img
          src={data.chairmanMessageBanner}
          alt="Chairman Background"
          className="chairman-home-image"
        />

        {/* Text Container */}
        <div className="chairman-home-text-container">
          {/* <h1 className="chairman-home-title">Chairman message</h1> */}
        </div>
      </div>

      <div className="about-chairman-container">
        <h1 className="about-chairman-title">
          About Chairman
          <div className="title-underline"></div>
        </h1>

        <div className="chairman-content">
          <div className="chairman-image-wrapper">
            <div className="aboutimage-container">
              <img
                src={data.chairmanPhoto}
                alt="Chairman"
                className="chairman-image"
              />
            </div>
          </div>

          <div className="chairman-info">
            <h2 className="chairman-name">Shyam</h2>
            <h3 className="chairman-designation">
              Director of Shyam Institute
            </h3>

            <p className="chairman-description">{data.aboutChairman}</p>
          </div>
        </div>
      </div>

      <div className="chairman-container">
        {/* Header Section */}
        <div className="header-section">
          <h1 className="main-title">
            Chairman's Message
            <div className="title-underline"></div>
          </h1>
        </div>

        <h2 className="subtitle">
          We are team of talented Teachers to educate courses
        </h2>

        <div className="chairman-content-wrapper">
          {/* Main Content */}
          <div className="text-content">
            <p>{data.chairmanMessage}</p>
          </div>

          {/* Media Container */}
          <div className="media-section">
            <div>
              <iframe
                className="chairman-video"
                width="560"
                height="315"
                src={data.chairmanMessageRedirect.replace("watch?v=", "embed/")}
                title="Chairman Message Video"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChairmanBanner;
