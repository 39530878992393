// NotificationsCenter.jsx
import React, { useState, useEffect } from 'react';
import './NotificationCenter.css';
// import NotificationImg from '../../assets/images/notification banner.jpg';
import BASE_URL from '../../components/link/link';

const CalendarIcon = () => (
  <svg className="calendar-icon" viewBox="0 0 24 24" fill="none" stroke="currentColor">
    <rect x="3" y="4" width="18" height="18" rx="2" ry="2" />
    <line x1="16" y1="2" x2="16" y2="6" />
    <line x1="8" y1="2" x2="8" y2="6" />
    <line x1="3" y1="10" x2="21" y2="10" />
  </svg>
);

const NotificationCard = ({ title, date, link }) => {
  const formattedDate = new Date(date).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  });

  return (
    <div className="notification-card">
      <div className="notification-card-content">
        <h2>{title}</h2>
        <div className="notification-date-wrapper">
          <CalendarIcon />
          <span>{formattedDate}</span>
        </div>
      </div>
      <a href={link} target="_blank" rel="noopener noreferrer" className="notification-read-more">
        <div className="notification-card-footer">
          Read More
        </div>
      </a>
    </div>
  );
};

const NotificationsCenter = () => {
  const [notifications, setNotifications] = useState([]);
  const [bannerImage, setBannerImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await fetch(`${BASE_URL}/notification/get-notifications`);
        if (!response.ok) {
          throw new Error('Failed to fetch notifications');
        }
        const data = await response.json();
        setNotifications(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchNotifications();
  }, []);


  useEffect(() => {
    const fetchBannerImage = async () => {
      try {
        const response = await fetch(`${BASE_URL}/banner/notification-centre`);
        if (!response.ok) {
          throw new Error("Failed to fetch banner image");
        }
        const bannerData = await response.json();
        if (bannerData && bannerData.length > 0) {
          setBannerImage(bannerData[0].imageUrl);
        }
      } catch (err) {
        console.error("Error fetching banner image:", err);
      }
    };

    fetchBannerImage();
  }, []);

  if (loading) {
    return <div className="loading">Loading notifications...</div>;
  }

  if (error) {
    return <div className="error">{error}</div>;
  }

  return (
    <div className="notifications-center">
      {/* Background and Title Section */}
      <div className="notification-home-container">
        <img 
          src={bannerImage || ''}
          alt="Notification Center Background" 
          className="notification-home-image"
        />
        <div className="notification-home-text-container">
          {/* <h1 className="notification-home-title">Notification Center</h1> */}
        </div>
      </div>

      {/* Notifications List */}
      <div className="notifications-container">
        {notifications.map(notification => (
          <NotificationCard
            key={notification._id}
            title={notification.name}
            date={notification.date}
            link={notification.link}
          />
        ))}
      </div>
    </div>
  );
};

export default NotificationsCenter;
