import React, { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import "./Scrollbar.css";
import BASE_URL from "../link/link";

function Scrollbar() {
  const [scrollingItems, setScrollingItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchScrollingText = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/homepage/all`
        );
        const data = await response.json();
        const fetchedScrollingItems = data.data?.scrollingText || [];
        setScrollingItems(fetchedScrollingItems);
      } catch (error) {
        console.error("Error fetching scrolling text:", error);
        setScrollingItems([]);
      } finally {
        setLoading(false);
      }
    };

    fetchScrollingText();
  }, []);

  return (
    <div>
      {loading ? (
        <p className="loading-text">Loading scrolling text...</p>
      ) : (
        <Marquee
          className="marquee-bar"
          speed={100}
          pauseOnHover={false}
          play={true} /* Ensure scrolling resumes automatically */
          autoFill={true} /* Fill space dynamically */
        >
          {scrollingItems.map((item, index) => (
            <div
              key={item._id}
              className={`scrolling-item-container ${
                index === 1 ? "extra-spacing" : ""
              }`}
            >
              <button
                onClick={() =>
                  window.open(item.link || "#", "_blank", "noopener,noreferrer")
                }
                className="click-me-button"
              >
                Click Here
              </button>
              <a
                href={item.link || "#"}
                target="_blank"
                rel="noopener noreferrer"
                className="scrolling-link"
              >
                {item.text}
              </a>
            </div>
          ))}
        </Marquee>
      )}
    </div>
  );
}

export default Scrollbar;
