import React, { useState, useEffect } from "react";
import "./Contact.css";
import contactusperson from "../../assets/images/contactus-person.png";
import MapContainer from "./MapContainer";
import BASE_URL from "../../components/link/link";
import { Helmet } from 'react-helmet';


const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    course: "",
    city: "",
    message: "",
  });
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [submissionStatus, setSubmissionStatus] = useState(null);
  const [bannerImage, setBannerImage] = useState(null); // State for banner image
  const [showSuccessPopup, setShowSuccessPopup] = useState(false); // New state for the success popup
  const [courses, setCourses] = useState([]); // State to hold fetched courses

  useEffect(() => {
    // Fetch the banner image from the API
    fetch(`${BASE_URL}/banner/contact`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setBannerImage(data[0].imageUrl); // Set the banner image from API
        }
      })
      .catch((error) => {
        console.error("Error fetching banner image:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch the interested courses from the API
    fetch(`${BASE_URL}/contact/get-interested-courses`) // Adjust API URL as per your setup
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setCourses(data); // Set the courses from API
        } else {
          console.error("No courses found");
        }
      })
      .catch((error) => {
        console.error("Error fetching courses:", error);
      });
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormSubmitting(true);

    // Send form data to the server
    fetch(`${BASE_URL}/contact/submit-form`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((res) => res.json())
      .then((data) => {
        setSubmissionStatus("success");
        alert("Thanks for submitting, our team will reach you soon!");
        setFormData({
          name: "",
          mobile: "",
          email: "",
          course: "",
          city: "",
          message: "",
        });
        setShowSuccessPopup(true);
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setSubmissionStatus("error");
        alert("There was an error submitting your form. Please try again.");
      })
      .finally(() => {
        setFormSubmitting(false);
      });
  };

  const closeSuccessPopup = () => {
    setShowSuccessPopup(false); // Close the popup
  };

  return (
    <>
    <Helmet>
        <title>Contact Shyam Institute | Coaching Centers in AP & TS</title>
        <meta 
          name="description" 
          content="Get in touch with Shyam Institute for inquiries about our courses, test series, and admissions. Find our contact details and reach out to us for personalized guidance." 
        />
      </Helmet>

    <div>
      <div className="contact-page">
        <div className="contactpage-container">
          {bannerImage ? (
            <img
              src={bannerImage}
              alt="Contact Background"
              className="contactheading-image"
            />
          ) : (
            <p className="loading-text">Loading Banner...</p>
          )}
        </div>

        <section className="contact-form-section">
          <div className="form-main">
            <div className="form-heading">
              <p>
                Please complete the form below. For any counseling or additional
                assistance, feel free to contact us.
              </p>
            </div>
            <div className="form-container">
              <form onSubmit={handleSubmit}>
                <label>
                  Name:<span className="contact-required">*</span>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                  />
                </label>
                <br />
                <label>
                  Mobile Number:<span className="contact-required">*</span>
                  <input
                    type="tel"
                    name="mobile"
                    value={formData.mobile}
                    onChange={handleChange}
                    required
                    pattern="[0-9]{10}"
                    title="Mobile number must be 10 digits"
                  />
                </label>
                <br />
                <label>
                  Email ID:<span className="contact-required">*</span>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                    title="Please enter a valid email address"
                  />
                </label>
                <br />
                <label>
                  Interested Course:<span className="contact-required">*</span>
                  <select
                    name="course"
                    value={formData.course}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Select a course</option>
                    {courses.length > 0 ? (
                      courses.map((course, idx) => (
                        <option key={idx} value={course.courseName}>
                          {course.courseName}
                        </option>
                      ))
                    ) : (
                      <option disabled>Loading courses...</option>
                    )}
                  </select>
                </label>
                <br />
                <label>
                  City:<span className="contact-required">*</span>
                  <input
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    required
                    pattern="[A-Za-z\s]+"
                    title="City name should only contain letters"
                  />
                </label>
                <br />
                <label>
                  Message:<span className="contact-required">*</span>
                  <br />
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleChange}
                    required
                  />
                </label>
                <br />
                <button type="submit" disabled={formSubmitting}>
                  {formSubmitting ? "Submitting..." : "Submit"}
                </button>
                {submissionStatus && submissionStatus === "error" && (
                  <p className="error-message">
                    Error submitting form. Please try again.
                  </p>
                )}
              </form>
            </div>
          </div>
          <div className="form-image">
            <img
              src={contactusperson}
              alt="Contact Person"
              className="contact-image"
            />
          </div>
        </section>

        <section className="map-section">
          <div className="map-container">
            <MapContainer />
          </div>
        </section>
      </div>
    </div>
    </>
  );
};

export default Contact;
