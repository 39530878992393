import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Resetpassword.css';
import BASE_URL from '../../components/link/link';

const ResetPassword = () => {
  const { token } = useParams();
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupMessage, setPopupMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setPopupMessage('Passwords do not match');
      setPopupVisible(true);
      return;
    }

    try {
      const response = await axios.post(`${BASE_URL}/user/reset-password/${token}`, {
        password
      });

      if (response.status === 200) {
        setTimeout(() => {
          setPopupMessage('Password successfully changed!');
          setPopupVisible(true);
          navigate('/login');
        }, 3000); // Redirect after 3 seconds
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setPopupMessage('Error resetting password. Please try again.');
      setPopupVisible(true);
    }
  };

  const handlePopupClose = () => {
    setPopupVisible(false);  // Close the popup
    navigate('/login');      // Redirect to login page
  };

  return (
    <div className="reset-password-container">
      <h2 className="reset-password-title">Reset Your Password</h2>
      <form className="reset-password-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label className="form-label">New Password</label>
          <input
            type="password"
            className="form-input"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label className="form-label">Confirm Password</label>
          <input
            type="password"
            className="form-input"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-btn">Reset Password</button>
      </form>

      {/* Popup for Error */}
      {popupVisible && (
        <div className="reset-popup-overlay">
          <div className="reset-popup-container">
            <p>{popupMessage}</p>
            <button className="reset-popup-btn" onClick={handlePopupClose}>OK</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
