import React, { useState, useEffect } from "react";
import "./ExamCalender.css";
import BASE_URL from "../../components/link/link";

const ExamCalendar = () => {
  const [calendarData, setCalendarData] = useState([]);
  const [bannerImage, setBannerImage] = useState(null);

  // Fetch calendar data from the API
  useEffect(() => {
    fetch(`${BASE_URL}/examcalender/all`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          const sortedData = data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
          setCalendarData(sortedData);
        }
      })
      .catch((error) => console.error("Error fetching exam calendar data:", error));
  }, []);

  // Fetch banner image from the API
  useEffect(() => {
    fetch(`${BASE_URL}/banner/examCalender`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setBannerImage(data[0].imageUrl); // Set the banner image URL from the first item
        }
        else {
          console.error("Invalid API response:", data);
        }
      })
      .catch((error) => console.error("Error fetching banner image:", error));
  }, []);

  return (
    <>
      {/* Render the banner image */}
      <div className="exam-container">
        {bannerImage ? (
          <img src={bannerImage} alt="Exam Background" className="exam-image" />
        ) : (
          <p className="loading-text">Loading Banner...</p>
        )}
      </div>

      <div className="exam-calendar-container">
        <div className="exam-calendar-header">
          <h1>
            <span className="blue-text">Exam</span>
            <span className="yellow-text">Calendar</span>
          </h1>
          <div className="underline"></div>
        </div>

        <div className="exam-calendar-grid">
          {calendarData.map((item) => (
            <div
              key={item._id}
              className="calendar-item"
              onClick={() => window.open(item.pdfLink, "_blank")}
              style={{ cursor: "pointer" }}
              title="Download Calendar"
            >
              <div className="calendar-content">
                <h3 className="calendar-title">{item.title}</h3>
                <span className="download-icon">⤓</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default ExamCalendar;
