import React, { useEffect, useState } from "react";
import './MissionVision.css';
import BASE_URL from "../../components/link/link";
import { Helmet } from "react-helmet";

const MissionVisionBanner = () => {
  const [data, setData] = useState(null);
  const [bannerImage, setBannerImage] = useState(null);

  // Fetch data from the backend API
  useEffect(() => {
    fetch(`${BASE_URL}/mission-vision/get`)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.missionAndVision) {
          setData(data.missionAndVision);
          // console.log(data.missionAndVision)
        }
      })
      .catch((error) => console.error("Error fetching mission and vision data:", error));
  }, []);


  useEffect(() => {
    fetch(`${BASE_URL}/banner/mission-and-vision`)
      .then((response) => response.json())
      .then((bannerData) => {
        if (bannerData && bannerData.length > 0) {
          setBannerImage(bannerData[0].imageUrl);
        }
      })
      .catch((error) => console.error("Error fetching banner image:", error));
  }, []);

  // If data is not yet loaded, show a loading message
  if (!data || !bannerImage) {
    return <div className="loading">Loading...</div>;
  }

  return (
    <>
    <Helmet>
        <title>About Shyam Institute: Leading Coaching Center in AP & TS Since 2001</title>
        <meta 
          name="description" 
          content="Learn about Shyam Institute's journey since 2001 as the No.1 coaching institute in Andhra Pradesh and Telangana. Discover our mission, achievements, and commitment to student success." 
        />
      </Helmet>
      <div className="mission-vision-home-container">
        {/* Background Image */}
        <img
          src={bannerImage} // Using missionImage as a placeholder for background
          alt="Mission & Vision Background"
          className="mission-vision-home-image"
        />

        {/* Text Container */}
        <div className="mission-vision-home-text-container">
          {/* <h1 className="mission-vision-home-title">Our Mission & Vision</h1> */}
        </div>
      </div>

      <div className="mission-container">
        {/* Mission Section */}
        <div className="mission-section mission-div">
          <div className="mission-section-image-container">
            <img
              src={data.missionImage}
              alt="Mission_image"
              className="mission-section-image"
            />
          </div>
          <div className="mission-section-text">
            <div className="mission-header">
              <div className="mission-vertical-line"></div>
              <h2 className="mission-highlight">Our Mission</h2>
            </div>
            <p className="mission-para">{data.mission}</p>
          </div>
        </div>

        {/* Vision Section */}
        <div className="mission-section vision-div">
          <div className="mission-section-text">
            <div className="mission-header">
              <div className="mission-vertical-line"></div>
              <h2 className="mission-highlight">Our Vision</h2>
            </div>
            <p className="mission-para">{data.vision}</p>
          </div>
          <div className="mission-section-image-container">
            <img
              src={data.visionImage}
              alt="Vision_image"
              className="mission-section-image"
            />
          </div>
        </div>

        {/* Values Section */}
        <div className="mission-section mission-div">
          <div className="mission-section-image-container">
            <img
              src={data.valuesImage}
              alt="Values_image"
              className="mission-section-image"
            />
          </div>
          <div className="mission-section-text">
            <div className="mission-header">
              <div className="mission-vertical-line"></div>
              <h2 className="mission-highlight">Our Values</h2>
            </div>
            <p className="mission-para">{data.values}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default MissionVisionBanner;
