import React from 'react';
import './RefundPolicy.css'

const RefundPolicy = () => {
  return (
    <div className="refund-policy-container">
      <h1>Cancellation & Refund Policy</h1>
      
      <p>At Shyam Institute, we strive to provide the best learning experience to our students. However, we understand that there may be situations where you need to request a refund. Please read our refund policy below for details:</p>
      
      <h2>1. Eligibility for Refunds:</h2>
      <ul>
        <li>Refunds are available only for courses that have been paid for but not yet started.</li>
        <li>If the course has already started, we are unable to issue a refund. However, you may be eligible for a transfer to another course or a deferral (please see point 3).</li>
      </ul>

      <h2>2. Refund Request Timeline:</h2>
      <ul>
        <li>Refund requests must be made within 7 days of the course enrollment date or payment date (whichever is applicable).</li>
        <li>After this period, no refunds will be processed.</li>
      </ul>

      <h2>3. Special Circumstances:</h2>
      <ul>
        <li>In the event of a medical emergency or other extenuating circumstances, we may consider an exception to the refund policy. Please provide appropriate documentation, and we will evaluate the situation on a case-by-case basis.</li>
        <li>If the course is canceled by Shyam Institute for any reason, you will receive a full refund.</li>
      </ul>

      <h2>4. Non-Refundable Fees:</h2>
      <ul>
        <li>Any registration fees, materials fees, or administrative charges are non-refundable.</li>
        <li>If a course includes non-refundable materials, such as textbooks or software, the cost of these materials will be deducted from the refund amount.</li>
      </ul>

      <h2>5. How to Request a Refund:</h2>
      <ul>
        <li>To request a refund, please contact us at [email address] or [phone number]. Include your course name, payment details, and the reason for the refund request.</li>
        <li>Once your refund request is received, we will review it and notify you of the status within 7 business days.</li>
      </ul>

      <h2>6. Refund Method:</h2>
      <ul>
        <li>Refunds will be processed via the original payment method. Please allow up to 10 business days for the refund to appear in your account, depending on your payment provider.</li>
      </ul>

      <h2>7. Course Transfers or Deferrals:</h2>
      <ul>
        <li>If you are unable to attend the course, we may offer the option to transfer to another course or defer your enrollment to a future date (subject to availability).</li>
      </ul>
    </div>
  );
};

export default RefundPolicy;
