import React, { useState, useEffect } from "react";
import logo from "../../assets/images/updatelogo.png";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import Loginbutton from '../Login-Logout/Loginbutton'

function Navbar() {
  const [activeLink, setActiveLink] = useState("");
  const [isSidebarOpen, setSidebarOpen] = useState(false);
  const [openDropdowns, setOpenDropdowns] = useState({
    about: false,
    exams: false,
    informationcenter: false,
    profile: false,
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    // Clean up the side effects when the component is unmounted or re-rendered
    return () => {
      document.body.style.overflowY = "auto";
  
      const outlet = document.getElementById("outlet");
      const navbar = document.querySelector(".navbar");
  
      if (outlet) {
        outlet.classList.remove("shifted");
      }
  
      if (navbar) {
        navbar.classList.remove("shifted");
      }
    };
  }, []);
  

  const handleLinkClick = (link) => {
    setActiveLink(link);
    setSidebarOpen(false);
    // Close all dropdowns when a link is clicked
    setOpenDropdowns({
      about: false,
      exams: false,
      informationcenter: false,
    });
    document.getElementById("outlet").classList.remove("shifted");
    document.querySelector(".navbar").classList.remove("shifted");

    if (link === "login") {
      setActiveLink("login");
    }
  };

  // Modified toggleDropdown function
  // const toggleDropdown = (e, dropdownName) => {
  //   e.preventDefault(); // Prevent default behavior
  //   if (isSidebarOpen) {
  //     setOpenDropdowns(prev => ({
  //       ...prev,
  //       [dropdownName]: !prev[dropdownName]
  //     }));
  //   }
  // };


  //working code
  const toggleDropdown = (e, dropdownName) => {
    e.preventDefault();
    e.stopPropagation();
    setOpenDropdowns((prev) => {
      const newState = {
        ...prev,
        [dropdownName]: !prev[dropdownName]
      };
      return newState;
    });
  };


  const toggleSidebar = () => {
    setSidebarOpen(!isSidebarOpen);
    document
      .getElementById("outlet")
      .classList.toggle("shifted", !isSidebarOpen);
    document
      .querySelector(".navbar")
      .classList.toggle("shifted", !isSidebarOpen);

    if (!isSidebarOpen) {
      document.body.style.overflowY = "auto";
    } else {
      document.body.style.overflow = "auto";
      // Reset dropdowns when closing sidebar
      setOpenDropdowns({
        about: false,
        exams: false,
        informationcenter: false,
      });
    }
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
    document.getElementById("outlet").classList.remove("shifted");
    document.querySelector(".navbar").classList.remove("shifted");
    document.body.style.overflow = "auto";
    // Reset dropdowns when closing sidebar
    setOpenDropdowns({
      about: false,
      exams: false,
      informationcenter: false,
    });
  };

  const handleLogin = () => {
    setIsLoggedIn(true);
  };

  const handleLogout = () => {
    setIsLoggedIn(false);
  };

  return (
    <>
      <nav className="navbar" id="navbar">
        <div className="navbar-header">
          <div>
            <button className="menu-toggle" onClick={toggleSidebar}>
              <FontAwesomeIcon icon={isSidebarOpen ? faTimes : faBars} />
            </button>
          </div>
          <Link to="/" className="navbar-logo">
            <img src={logo} alt="Shyam Institute Logo" className="logo-image" />
          </Link>
        </div>

        <div className="navigations">
          <ul className={`navbar-links ${isSidebarOpen ? "sidebar-open" : ""}`}>
            {/* Home Link */}
            <li>
              <Link
                to="/"
                onClick={() => handleLinkClick("home")}
                className={activeLink === "home" ? "active" : ""}
              >
                Home
              </Link>
            </li>

            {/* About Dropdown */}
            <li
              className={`dropdown ${openDropdowns.about ? "dropdown-active" : ""
                }`}
            >
              <Link
                to="#"
                onClick={(e) => toggleDropdown(e, "about")}
                className={
                  activeLink === "chairman-message" ||
                    activeLink === "mission&vision" ||
                    activeLink === "shyam-methodology"||
                    activeLink === "blog"
                    ? "active"
                    : ""
                }
              >
                About{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{
                    marginLeft: "5px",
                    fontSize: "0.8em",
                    transform: openDropdowns.about
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                />
              </Link>
              <ul
                className={`dropdown-menu ${openDropdowns.about ? "show" : ""}`}
              >
                <li>
                  <Link
                    to="/chairman-message"
                    onClick={() => handleLinkClick("chairman-message")}
                    className={
                      activeLink === "chairman-message" ? "active" : ""
                    }
                  >
                    Chairman Message
                  </Link>
                </li>
                <li>
                  <Link
                    to="/mission&vision"
                    onClick={() => handleLinkClick("mission&vision")}
                    className={activeLink === "mission&vision" ? "active" : ""}
                  >
                    Mission & Vision
                  </Link>
                </li>
                <li>
                  <Link
                    to="/shyam-methodology"
                    onClick={() => handleLinkClick("shyam-methodology")}
                    className={
                      activeLink === "shyam-methodology" ? "active" : ""
                    }
                  >
                    Shyam Methodology
                  </Link>
                </li>
                <li>
                  <Link
                    to="/blog"
                    onClick={() => handleLinkClick("blog")}
                    className={
                      activeLink === "blog" ? "active" : ""
                    }
                  >
                    Blog
                  </Link>
                </li>
              </ul>
            </li>

            {/* Courses Link */}
            <li>
              <Link
                to="/courses"
                onClick={() => handleLinkClick("courses")}
                className={activeLink === "courses" ? "active" : ""}
              >
                Courses
              </Link>
            </li>

            {/* Exams Dropdown */}
            <li
              className={`dropdown ${openDropdowns.exams ? "dropdown-active" : ""
                }`}
            >
              <Link
                to="#"
                onClick={(e) => toggleDropdown(e, "exams")}
                className={
                  activeLink === "exam-calendar" ||
                    activeLink === "results" ||
                    activeLink === "notification-center" ||
                    activeLink === "previous-papers"
                    ? "active"
                    : ""
                }
              >
                Updates{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{
                    marginLeft: "5px",
                    fontSize: "0.8em",
                    transform: openDropdowns.exams ? "rotate(180deg)" : "none",
                    transition: "transform 0.3s ease",
                  }}
                />
              </Link>
              <ul
                className={`dropdown-menu ${openDropdowns.exams ? "show" : ""}`}
              >
                <li>
                  <Link
                    to="/exam-calendar"
                    onClick={() => handleLinkClick("exam-calendar")}
                    className={activeLink === "exam-calendar" ? "active" : ""}
                  >
                    Exam Calendar
                  </Link>
                </li>
                <li>
                  <Link
                    to="/results"
                    onClick={() => handleLinkClick("results")}
                    className={activeLink === "results" ? "active" : ""}
                  >
                    Results
                  </Link>
                </li>
                <li>
                  <Link
                    to="/notification-center"
                    onClick={() => handleLinkClick("notification-center")}
                    className={
                      activeLink === "notification-center" ? "active" : ""
                    }
                  >
                    Notification Center
                  </Link>
                </li>
                <li>
                  <Link
                    to="/previous-papers"
                    onClick={() => handleLinkClick("previous-papers")}
                    className={activeLink === "previous-papers" ? "active" : ""}
                  >
                    Previous Exam Papers
                  </Link>
                </li>
              </ul>
            </li>

            <li>
              <Link
                to="/materials"
                onClick={() => handleLinkClick("materials")}
                className={activeLink === "materials" ? "active" : ""}
              >
                Materials
              </Link>
            </li>

            <li
              className={`dropdown ${openDropdowns.informationcenter ? "dropdown-active" : ""
                }`}
            >
              <Link
                to="#"
                onClick={(e) => toggleDropdown(e, "informationcenter")}
                className={
                  activeLink === "information-center" ||
                    activeLink === "ourcenters"
                    ? "active"
                    : ""
                }
              >
                Information Center{" "}
                <FontAwesomeIcon
                  icon={faChevronDown}
                  style={{
                    marginLeft: "5px",
                    fontSize: "0.8em",
                    transform: openDropdowns.informationcenter
                      ? "rotate(180deg)"
                      : "rotate(0deg)",
                    transition: "transform 0.3s ease",
                  }}
                />
              </Link>

              <ul
                className={`dropdown-menu ${openDropdowns.informationcenter ? "show" : ""}`}
              >
                <li>
                  <Link
                    to="/our-centers"
                    onClick={() => handleLinkClick("ourcenters")}
                    className={activeLink === "ourcenters" ? "active" : ""}
                  >
                    Examination Centers
                  </Link>
                </li>
                <li>
                  <Link
                    to="/upcoming-batches"
                    onClick={() => handleLinkClick("information-center")}
                    className={activeLink === "information-center" ? "active" : ""}
                  >
                    Upcoming Batches
                  </Link>
                </li>
              </ul>
            </li>

            {/* Rest of the navigation items */}

            


            <li>
              <Link
                to="/success-stories"
                onClick={() => handleLinkClick("success-stories")}
                className={activeLink === "success-stories" ? "active" : ""}
              >
                Success Stories
              </Link>
            </li>

            
            <li>
              <Link
                to="/gallery"
                onClick={() => handleLinkClick("gallery")}
                className={activeLink === "gallery" ? "active" : ""}
              >
                Gallery
              </Link>
            </li>
            <li>
              <Link
                to="/subscription"
                onClick={() => handleLinkClick("subscription")}
                className={activeLink === "subscription" ? "active" : ""}
              >
                Test Series
              </Link>
            </li>
            <li>
              <Link
                to="/contact"
                onClick={() => handleLinkClick("contact")}
                className={activeLink === "contact" ? "active" : ""}
              >
                Contact
              </Link>
            </li>
            <li>
              <Loginbutton onLogin={handleLogin} onLogout={handleLogout} isLoggedIn={isLoggedIn} handleLinkClick={handleLinkClick}
                toggleDropdown={toggleDropdown}
                dropdownVisible={openDropdowns.profile}
                activeLink={activeLink} />
            </li>
          </ul>
        </div>
      </nav>

      {/* Overlay div */}
      {isSidebarOpen && <div className="overlay" onClick={closeSidebar}></div>}
    </>
  );
}

export default Navbar;