import React, { useEffect, useState } from "react";
import "./ExamResults.css";
import BASE_URL from "../../components/link/link";

const ExamResults = () => {
  const [examCards, setExamCards] = useState([]);
  const [bannerImage, setBannerImage] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Fetch exam results
    const fetchExamResults = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/examresult/all`
        );
        const data = await response.json();
        setExamCards(data);
      } catch (error) {
        console.error("Error fetching exam results:", error);
      }
    };

    // Fetch banner image
    const fetchBannerImage = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/banner/examResults`
        );
        const data = await response.json();
        if (Array.isArray(data) && data.length > 0) {
          setBannerImage(data[0].imageUrl); // Access the first item in the array
        } else {
          console.error("Invalid API response:", data);
        }
      } catch (error) {
        console.error("Error fetching banner image:", error);
      }
    };

    // Load both data
    const fetchData = async () => {
      await Promise.all([fetchExamResults(), fetchBannerImage()]);
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <>
      {/* Background Image */}
      <div className="exam-result-container">
        {bannerImage && (
          <img
            src={bannerImage}
            alt="Exam Background"
            className="exam-result-image"
          />
        )}
      </div>

      <div className="examresult-container">
        <h1 className="title">
          <span>Exam </span>
          <span>Results</span>
        </h1>

        {/* Render Loading State */}
        {loading ? (
          <p className="loading-text">Loading...</p>
        ) : (
          <div className="cards-container">
            {examCards.map((card) => (
              <div key={card._id} className="card"
                onClick={() => window.open(card.pdfLink, "_blank")}
                style={{ cursor: "pointer" }}
                title="View PDF">
                <div className="image-container">
                  <img
                    src={card.bannerImage}
                    alt={card.title}
                    className="result-image"
                  />
                </div>
                <div className="card-content">
                  <span className="result-text">{card.title}</span>
                  <div className="action-buttons">
                    {/* PDF Button */}
                    <a
                      href={card.pdfLink}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="action-button"
                      title="View PDF"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                        <polyline points="14 2 14 8 20 8" />
                      </svg>
                    </a>

                    {/* Download Button stopped from updation on 21/02/2025 */}
                    {/* <a
                      href={card.pdfLink}
                      target="_blank"
                      download
                      className="action-button"
                      title="Download"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                        <polyline points="7 10 12 15 17 10" />
                        <line x1="12" y1="15" x2="12" y2="3" />
                      </svg>
                    </a> */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default ExamResults;
