import React, { useState, useEffect } from "react";
import "./Cart.css";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faPlus,
  faMinus,
  faCartShopping
} from "@fortawesome/free-solid-svg-icons";
import BASE_URL from "../../components/link/link";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useStudentId from "../../components/link/authutils";


const Cart = () => {
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const { studentId, isLoading } = useStudentId();
  const navigate = useNavigate();
  // console.log(studentId)



  // Fetch cart items from API
  useEffect(() => {
    if (!studentId) {
      setLoading(false);
      return;
    }

    const fetchCartItems = async () => {
      try {
        const response = await fetch(`${BASE_URL}/cart/getCart/${studentId}`);
        if (!response.ok) {
          throw new Error("Failed to fetch cart items");
        }
        const data = await response.json();
        setCartItems(data.cart.items || []); // Handle empty cart
      } catch (error) {
        setError(true);
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, [studentId]);

  // Function to update quantity of an item
  const updateQuantity = async (itemId, newQuantity) => {
    try {
      const response = await fetch(`${BASE_URL}/cart/updateCart/${studentId}/update`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          items: [{ productId: itemId, quantity: newQuantity }],
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to update quantity");
      }

      const data = await response.json();
      setCartItems(data.cart.items); // Update local state with the updated cart
    } catch (error) {
      console.error("Error updating quantity:", error);
    }
  };

  // Function to increase quantity
  const handleIncreaseQuantity = (itemId) => {
    const item = cartItems.find((item) => item.productId === itemId);
    if (item) {
      updateQuantity(itemId, item.quantity + 1);
    }
  };

  // Function to decrease quantity
  const handleDecreaseQuantity = (itemId) => {
    const item = cartItems.find((item) => item.productId === itemId);
    if (item && item.quantity > 1) {
      updateQuantity(itemId, item.quantity - 1);
    }
  };

  // Function to remove an item from the cart
  const handleRemoveItem = async (itemId) => {
    try {
      const response = await fetch(`${BASE_URL}/cart/removeCart/${studentId}/remove`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          productId: itemId,
        }),
      });

      if (!response.ok) {
        throw new Error("Failed to remove item");
      }

      const data = await response.json();
      setCartItems(data.cart.items); // Update local state with the updated cart
    } catch (error) {
      console.error("Error removing item:", error);
    }
  };

  // Calculate the total amount of all items in the cart
  const totalAmount = cartItems.reduce(
    (total, item) => total + item.price * (item.quantity || 1), // Fallback to 1 if quantity is missing
    0
  );

  // Function to proceed to the address page
  const handleProceed = () => {
    localStorage.removeItem("buyNowItem");
    navigate("/checkout");
  };

  if (isLoading) {
    return (
      <div className="cart-container">
        <div className="loading-spinner">
        <p className="loading-cart-text">Loading your </p><FontAwesomeIcon icon={faCartShopping} className="loading-cart-icon" />
        </div>
      </div>
    );
  }
  
  if (!studentId) {
    return (
      <div className="cart-container">
        <div className="login-prompt">
          <h2>Please Login First</h2>
          <p>You need to be logged in to view your cart</p>
          <button 
            className="login-button"
            onClick={() => navigate("/login")}
          >
            Go to Login
          </button>
        </div>
      </div>
    );
  }


  return (
    <div className="cart-container">
      <div className="back-button">
        <Link to="/materials">
          <FontAwesomeIcon icon={faAnglesLeft} /> Back to Materials
        </Link>
      </div>
      <h1>Your Cart</h1>

      {loading ? (
        <p>Loading your cart...</p>
      ) : error ? (
        <p className="error-message">Failed to load cart items. Please try again later.</p>
      ) : cartItems.length === 0 ? (
        <p className="empty-cart-message">Your cart is empty.</p>
      ) : (
        <>
          <div className="cart-items">
            {cartItems.map((item) => (
              <div key={item.productId || item._id} className="cart-item">
                <img
                  src={item.imageUrl}
                  alt={item.name}
                  className="cart-item-image"
                />
                <div className="cart-item-details">
                  <h3>{item.name}</h3>
                  {item.subtopic && (
                    <p className="item-subtopic">{item.subtopic}</p>
                  )}
                  <p className="item-price">₹{item.price}</p>
                </div>
                <div className="item-actions">
                  <div className="quantity-controls">
                    <button
                      className="quantity-button"
                      onClick={() => handleDecreaseQuantity(item.productId)}
                      disabled={item.quantity <= 1}
                    >
                      <FontAwesomeIcon className="icon" icon={faMinus} />
                    </button>
                    <span className="quantity">{item.quantity || 1}</span>{" "}
                    {/* Fallback to 1 if quantity is missing */}
                    <button
                      className="quantity-button"
                      onClick={() => handleIncreaseQuantity(item.productId)}
                      disabled={item.quantity >= 10}
                    >
                      <FontAwesomeIcon className="icon" icon={faPlus} />
                    </button>
                  </div>
                  <button
                    className="remove-button"
                    onClick={() => handleRemoveItem(item.productId)}
                  >
                    REMOVE
                  </button>
                </div>
              </div>
            ))}
          </div>

          {/* Cart Summary */}
          <div className="cart-summary">
            <h3>Total Amount: ₹{totalAmount}</h3>
            <button
              className="proceed-button"
              onClick={handleProceed}
            >
              Proceed
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Cart;
