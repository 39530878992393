import React, { useState, useEffect } from "react";
import "./Material.css";
import BASE_URL from "../../components/link/link";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCartShopping } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import useStudentId from "../../components/link/authutils";
import { Helmet } from 'react-helmet';

const Material = () => {
  const [cartCount, setCartCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [materials, setMaterials] = useState([]);
  const [filteredMaterials, setFilteredMaterials] = useState([]);
  const [ismaterialsLoading, setIsmaterialsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const { studentId, isLoading } = useStudentId();

  const itemsPerPage = 12;

  useEffect(() => {
    // Fetch data from API
    const fetchMaterials = async () => {
      try {
        const response = await fetch(`${BASE_URL}/material/all`);
        if (!response.ok) {
          throw new Error("Failed to fetch materials");
        }
        const data = await response.json();
        setMaterials(data);
      } catch (err) {
        setError(err.message);
      } finally {
        setIsmaterialsLoading(false);
      }
    };

    fetchMaterials();

    // Only fetch cart if studentId exists
    if (studentId) {
      const fetchCartCount = async () => {
        try {
          const response = await fetch(`${BASE_URL}/cart/getCart/${studentId}`);
          if (!response.ok) {
            throw new Error("Failed to fetch cart count");
          }
          const data = await response.json();
          const cartItems = data.cart.items || [];
          setCartCount(cartItems.length);
        } catch (err) {
          console.error("Error fetching cart count:", err);
        }
      };
      fetchCartCount();
    }
  }, [studentId]);

  useEffect(() => {
    if (studentId) {
      const fetchCartItems = async () => {
        try {
          const response = await fetch(`${BASE_URL}/cart/getCart/${studentId}`);
          if (!response.ok) {
            throw new Error("Failed to fetch cart items");
          }
          const data = await response.json();
          setCartItems(data.cart?.items || []);
        } catch (err) {
          console.error("Error fetching cart:", err);
        } finally {
          setLoading(false);
        }
      };
      fetchCartItems();
    } else {
      // If no studentId, just set loading to false
      setLoading(false);
    }
  }, [studentId]);

  const isItemInCart = (itemId) =>
    cartItems.some((item) => item.productId === itemId);

  const handleAddToCart = async (material) => {
    if (!studentId) {
      toast.error("Please login first to add items to cart", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    try {
      const item = {
        productId: material._id,
        name: material.name,
        price: material.price,
        quantity: 1,
        imageUrl: material.banner,
        subtopic: material.subtopic || "General",
      };

      const response = await fetch(`${BASE_URL}/cart/${studentId}/add`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ items: [item] }),
      });

      if (!response.ok) {
        throw new Error("Failed to add item to cart");
      }

      const data = await response.json();
      setCartCount(data.cart.items.length);
      setCartItems(data.cart.items);
      toast.success("Item added to cart successfully!", {
        position: "top-center",
        autoClose: 2000,
      });
    } catch (err) {
      toast.error("Error adding item to cart", {
        position: "top-center",
        autoClose: 2000,
      });
    }
  };

  const handleBuyNow = (material) => {
    if (!studentId) {
      toast.error("Please login first to make a purchase", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    localStorage.setItem(
      "buyNowItem",
      JSON.stringify({ ...material, quantity: 1 })
    );
    navigate("/checkout");
  };

  const handleGoToCart = () => {
    if (!studentId) {
      toast.error("Please login first to view cart", {
        position: "top-center",
        autoClose: 2000,
      });
      return;
    }
    navigate("/cart");
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 250, behavior: "smooth" });
  };

  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = materials.filter(
      (material) =>
        material.name.toLowerCase().includes(query) ||
        (material.subtopic && material.subtopic.toLowerCase().includes(query))
    );
    setFilteredMaterials(filtered);
    setCurrentPage(1);
  };

  const totalPages = Math.ceil(
    (searchQuery ? filteredMaterials.length : materials.length) / itemsPerPage
  );

  const paginatedMaterials = (searchQuery ? filteredMaterials : materials)
    .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
    .slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

  const isNewMaterial = (updatedAt) => {
    const updatedDate = new Date(updatedAt);
    const currentDate = new Date();
    const timeDifference = currentDate - updatedDate;
    const daysDifference = timeDifference / (1000 * 60 * 60 * 24);
    return daysDifference <= 7;
  };

  return (
    <>
    <Helmet>
        <title>UPSC Study Materials | Download High-Quality Notes – Shyam Institute</title>
        <meta name="description" content="Access premium UPSC study materials at Shyam Institute. Download high-quality notes, test series, and reference materials for Prelims & Mains preparation. Start your journey to success today!" />
      </Helmet>

    <div className="material-container">
      <header>
        <h1>
          <span className="blue-text">Our</span>{" "}
          <span className="yellow-text">Materials</span>
        </h1>
        <p className="description">
          We are proudly inform you that our students achieved more than 18000
          Government Jobs in various sectors. We are providing quality education
          to students by our well revised materials, well experienced faculty
          team and with perfect planned curriculum.
        </p>
      </header>

      <div className="search-section">
        <input
          type="text"
          placeholder="Search for materials..."
          className="search-input"
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <div className="cart-icon" onClick={handleGoToCart} style={{ cursor: 'pointer' }}>
          <FontAwesomeIcon icon={faCartShopping} size="2x" />
          {studentId && <span className="cart-count">{cartCount}</span>}
        </div>
      </div>

      <div className="materials-grid">
        {ismaterialsLoading && <p>Loading materials...</p>}
        {error && <p>{error}</p>}
        {!ismaterialsLoading &&
          !error &&
          (searchQuery ? filteredMaterials : paginatedMaterials).map(
            (material) => (
              <div key={material._id} className="materials-card">
                {isNewMaterial(material.updatedAt) && (
                  <div className="badge">New</div>
                )}
                <img
                  src={material.banner}
                  alt={material.name}
                  className="card-image"
                />
                <div className="materials-names">
                  <h3 className="card-title">{material.name}</h3>
                  {material.subtopic && (
                    <h3 className="card-subtopic">{material.subtopic}</h3>
                  )}
                  <div className="card-price-wrapper">
                    <p className="card-price">M.R.P ₹{material.price}</p>
                    <span
                      className={`stock-badge ${material.stock ? "in-stock" : "out-of-stock"
                        }`}
                    >
                      {material.stock ? "In Stock" : "Out of Stock"}
                    </span>
                  </div>
                  <div className="card-buttons">
                    <button
                      className="btn buy-now"
                      onClick={() => handleBuyNow(material)}
                      disabled={!material.stock}
                    >
                      Buy Now
                    </button>
                    {isItemInCart(material._id) ? (
                      <button
                        className="btn go-to-cart"
                        onClick={handleGoToCart}
                      >
                        Go to Cart
                      </button>
                    ) : (
                      <button
                        className="btn add-to-cart"
                        onClick={() => handleAddToCart(material)}
                        disabled={!material.stock}
                      >
                        Add to Cart
                      </button>
                    )}
                  </div>
                </div>
              </div>
            )
          )}
      </div>

      {totalPages > 1 && (
        <div className="pagination">
          <button
            className="prev-page"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            ← Previous
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`page-number ${currentPage === index + 1 ? "active" : ""
                }`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="next-page"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            Next →
          </button>
        </div>
      )}

      <section className="newsletter">
        <h2>
          <span className="blue-text">Join Our</span>{" "}
          <span className="yellow-text">News Letter</span>
        </h2>
        <p className="newsletter-description">
          Subscribe To Our Newsletter And Receive The Latest News About Our
          Products And Services!
        </p>
        <button className="subscribe-btn">Subscribe</button>
      </section>
      <ToastContainer />
    </div>
    </>
  );
};

export default Material;
