import React, { useEffect, useState } from 'react';
import './ShyamMessage.css';
import BASE_URL from '../../components/link/link';
import { Helmet } from "react-helmet";

const ShyamMessage = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        // Fetch data from the backend API
        const fetchData = async () => {
            try {
                const response = await fetch(`${BASE_URL}/methodology/all`);
                const result = await response.json();
                setData(result[0]); // Assuming the response is an array and we need the first object
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, []);

    // Display a loading message until the data is fetched
    if (!data) {
        return <p>Loading...</p>;
    }

    return (
        <>
        <Helmet>
        <title>About Shyam Institute: Leading Coaching Center in AP & TS Since 2001</title>
        <meta 
          name="description" 
          content="Learn about Shyam Institute's journey since 2001 as the No.1 coaching institute in Andhra Pradesh and Telangana. Discover our mission, achievements, and commitment to student success." 
        />
      </Helmet>
            {/* Background Image Section */}
            <div className="shyam-methodology-container">
                <img
                    src={data.bannerImage}
                    alt="Chairman Background"
                    className="shyam-methodology-image"
                />
                <div className="shyam-methodology-text-container">
                    {/* <h1 className="shyam-methodology-title">Shyam Methodology</h1> */}
                </div>
            </div>

            {/* Highlight Content */}
            <div className="container">
                <p className="styled-paragraph">{data.highlightContent}</p>
            </div>

            {/* Interactive Classes Section */}
            <div className="mission-container">
                <div className="mission-section mission-div">
                    <div className="mission-section-image-container">
                        <img
                            src={data.interactiveClassesBanner}
                            alt="Interactive Classes"
                            className="mission-section-image"
                        />
                    </div>
                    <div className="mission-section-text">
                        <div className="mission-header">
                            <div className="mission-vertical-line"></div>
                            <h2 className="mission-highlight">Interactive Classes</h2>
                        </div>
                        <p className="mission-para">{data.interactiveClassesContent}</p>
                    </div>
                </div>

                {/* Study Material Section */}
                <div className="mission-section vision-div">
                    <div className="mission-section-text">
                        <div className="mission-header">
                            <div className="mission-vertical-line"></div>
                            <h2 className="mission-highlight">Study Material</h2>
                        </div>
                        <p className="mission-para">{data.studyMaterialContent}</p>
                    </div>
                    <div className="mission-section-image-container">
                        <img
                            src={data.studyMaterialBanner}
                            alt="Study Material"
                            className="mission-section-image"
                        />
                    </div>
                </div>

                {/* Assessments Section */}
                <div className="mission-section mission-div">
                    <div className="mission-section-image-container">
                        <img
                            src={data.assessmentsBanner}
                            alt="Assessments"
                            className="mission-section-image"
                        />
                    </div>
                    <div className="mission-section-text">
                        <div className="mission-header">
                            <div className="mission-vertical-line"></div>
                            <h2 className="mission-highlight">Assessments</h2>
                        </div>
                        <p className="mission-para">{data.assessmentsContent}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

// Export component
export default ShyamMessage;
