import React, { useState, useEffect } from "react";
import "./OurCenters.css";
import BASE_URL from "../../components/link/link";

function OurCenters() {
  const [currentPage, setCurrentPage] = useState(1);
  const [mapData, setMapData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
    const [bannerImage, setBannerImage] = useState("");
  const mapsPerPage = 9; // Number of maps to display per page

  // Fetch data from the API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/centers/centers-location/`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch data");
        }
        const data = await response.json();
        setMapData(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    const fetchBanner = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/banner/our-centres`
        );
        if (!response.ok) {
          throw new Error("Failed to fetch banner image");
        }
        const bannerData = await response.json();
        setBannerImage(bannerData[0].imageUrl);
      } catch (err) {
        setError(err.message);
      }
    };

    fetchData();
    fetchBanner();
  }, []);

  // Calculate total pages and slice data for the current page
  const totalPages = Math.ceil(mapData.length / mapsPerPage);
  const indexOfLastMap = currentPage * mapsPerPage;
  const indexOfFirstMap = indexOfLastMap - mapsPerPage;
  const currentMaps = mapData.slice(indexOfFirstMap, indexOfLastMap);

  // Handle page change
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  return (
    <>
      <div className="ourcenters-container">
        <img
          src={bannerImage}
          alt="ourcenters Background"
          className="ourcenters-image"
        />
        <div className="ourcenters-text-container">
          {/* <h1 className="ourcenters-title">Examination Centers</h1> */}
        </div>
      </div>

      <section className="maps-section">
        <div className="map-containers">
          {currentMaps.map((map, index) => (
            <div key={map._id || index} className="map-cards">
              <iframe
                src={map.locationUrl}
                width="100%"
                height="300"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title={`Map of ${map.city}`}
              ></iframe>
              <h3>
                <span style={{ color: "#1A45AC" }}>Shyam</span>{" "}
                <span style={{ color: "#F6C300" }}>Institute</span>
              </h3>
              <p>{map.address}</p>
              <p>
                {map.city}, {map.state} {map.pincode}
              </p>
              <p>Phone: {map.mobile}</p>
              <p>Email: {map.email}</p>
            </div>
          ))}
        </div>
        {/* Pagination Controls */}
        <div className="centers-pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              className={`centers-pagination-btn ${currentPage === index + 1 ? "active" : ""}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </section>
    </>
  );
}

export default OurCenters;
