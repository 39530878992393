import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./AllBlogs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";
import BASE_URL from "../../components/link/link";

const AllBlogs = () => {
  const [loading, setLoading] = useState(true);
  const [blogs, setBlogs] = useState([]);
  const [topBlogs, setTopBlogs] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const blogsPerPage = 10;

  useEffect(() => {
    const fetchBlogs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/blogs/all`, {
          params: {
            page: currentPage,
            limit: blogsPerPage,
          },
        });
        setBlogs(response.data.blogs || []);
        setTotalPages(response.data.totalPages);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching blogs:", error);
        setLoading(false);
      }
    };

    const fetchTopBlogs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/blogs/top-blogs`);
        setTopBlogs(response.data.topBlogs || []);
      } catch (error) {
        console.error("Error fetching top blogs:", error);
      }
    };

    fetchBlogs();
    fetchTopBlogs();
  }, [currentPage]);

  const filteredTopBlogs = topBlogs.filter((blog) =>
    blog.title.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const formatDate = (dateString) => {
    if (!dateString) return "Unknown date";
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  // Handle page change
  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  if (loading) return <p>Loading...</p>;
  return (
    <>
      <div className="all-blogs-container">
        <div className="blogs-list">
          {blogs.map((blog) => (
            <div key={blog._id} className="blog-card">
              <img
                src={blog.thumbnail}
                alt={blog.title || "Blog image"}
                className="blog-image"
              />
              <div className="blog-details">
                <h2 className="blog-title">{blog?.title || "Untitled Blog"}</h2>
                <p className="blog-category">
                  {blog?.category?.name || "Uncategorized"}
                </p>
                <p className="blog-date">
                  <FontAwesomeIcon icon={faCalendarDays} />{" "}
                  {formatDate(blog?.createdAt)}
                </p>
                <Link
                  to={`/blog/${blog.title.replace(/\s+/g, "-").toLowerCase()}`}
                  className="read-more"
                >
                  Read More
                </Link>
              </div>
            </div>
          ))}
        </div>

        <div className="blogsidebar">
          <div className="topblogs-search-input-container">
            <input
              type="text"
              placeholder="Search top blogs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="topblogs-search-icon"
            />
          </div>
          <h3>Top Blogs</h3>
          <ul>
            {filteredTopBlogs.map((blog) => (
              <li key={blog._id}>
                <Link
                  to={`/blog/${blog.title.replace(/\s+/g, "-").toLowerCase()}`}
                >
                  {blog.title}
                </Link>
                <p>Date: {new Date(blog.createdAt).toLocaleDateString()}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
      {/* Pagination Controls */}
      <div className="allblogs-pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          Page {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
    </>
  );
};

export default AllBlogs;
