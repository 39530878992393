import React, { useState, useEffect } from "react";
import "./SuccessStories.css";
import BASE_URL from "../../components/link/link";
import { Helmet } from 'react-helmet';

function SuccessStories() {
  const [testimonialsData, setTestimonialsData] = useState([]);
  const [bannerImageUrl, setBannerImageUrl] = useState("");

  // Fetch success stories data
  useEffect(() => {
    fetch(`${BASE_URL}/success-stories/get-success-stories`)
      .then((response) => response.json())
      .then((data) => setTestimonialsData(data))
      .catch((error) => console.error("Error fetching success stories:", error));
  }, []);

  // Fetch success banner image
  useEffect(() => {
    fetch(`${BASE_URL}/banner/successstories`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setBannerImageUrl(data[0].imageUrl); // Assuming the response is an array
        }
      })
      .catch((error) => console.error("Error fetching banner image:", error));
  }, []);

  return (
    <>
    <Helmet>
        <title>Success Stories | Inspiring UPSC Toppers – Shyam Institute</title>
        <meta name="description" content="Read inspiring success stories of UPSC toppers from Shyam Institute. Discover their strategies, challenges, and tips to crack the IAS exam. Get motivated for your UPSC journey!" />
      </Helmet>
      <div className="success-container">
        {bannerImageUrl ? (
          <img
            src={bannerImageUrl}
            alt="Success Background"
            className="success-image"
          />
        ) : (
          <p>Loading banner...</p>
        )}
      </div>

      <section className="storiesimg-container">
        <h2>
          <span style={{ color: "#1A45AC" }}>Our Success</span>{" "}
          <span
            style={{
              color: "#F6C300",
              textDecoration: "underline",
              textUnderlineOffset: "10px",
              textDecorationColor: "#1A45AC",
            }}
          >
            Stories
          </span>
        </h2>

        <div className="testimonials-container">
          <div className="testimonials-wrapper">
            {testimonialsData.map((testimonial) => (
              <div key={testimonial._id} className="testimonial-card">
                <img
                  src={testimonial.photo}
                  alt={testimonial.name}
                  className="testimonial-image"
                />
                <h3 className="testimonial-name">{testimonial.name}</h3>
                <p className="testimonial-position">{testimonial.achievement}</p>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
}

export default SuccessStories;
