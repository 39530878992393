import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import "./SingleBlog.css";
import BASE_URL from "../../components/link/link";
import DOMPurify from "dompurify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalendarDays,
  faMagnifyingGlass,
} from "@fortawesome/free-solid-svg-icons";


const SingleBlog = () => {
  const { title } = useParams(); // Get the title from the URL
  const [blog, setBlog] = useState(null);
  const [recentBlogs, setRecentBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");

  useEffect(() => {
    fetchBlog();
  }, [title]);

  const fetchBlog = async () => {
    try {
      const formattedTitle = title.replace(/-/g, " "); // Convert slug back to title
      const response = await axios.get(
        `${BASE_URL}/api/blogs/title/${formattedTitle}`
      );
      console.log("Fetched blog data:", response.data.blog);
      setBlog(response.data.blog);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.error("Error fetching the blog:", err);
    }
  };

  useEffect(() => {
    const fetchRecentBlogs = async () => {
      try {
        const response = await axios.get(`${BASE_URL}/api/blogs/all`, {
          params: {
            limit: 10,
            sort: "-createdAt",
          },
        });
        setRecentBlogs(response.data.blogs || []);
        setFilteredBlogs(response.data.blogs || []); // Initialize filteredBlogs with all blogs
      } catch (error) {
        console.error("Error fetching recent blogs:", error);
      }
    };

    fetchRecentBlogs();
  }, []);

  // Real-time filtering as user types
  useEffect(() => {
    const filtered = recentBlogs.filter((blog) =>
      blog.title.toLowerCase().includes(searchQuery.toLowerCase())
    );
    setFilteredBlogs(filtered);
  }, [searchQuery, recentBlogs]);

  const formatDate = (dateString) => {
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Date(dateString).toLocaleDateString("en-GB", options);
  };

  if (loading) return <p>Loading...</p>;
  if (!blog) return <p>Blog not found</p>;

  return (
    <div className="single-blog-container">
      <div className="single-blog">
        <h1>{blog.title}</h1>
        <img
          src={blog.thumbnail || "/path/to/fallback-image.jpg"}
          alt={blog.title}
          className="blog-image"
        />
        <div
          className="single-blog-content"
          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(blog.content) }}
        />
        <p className="meta">
          Category: {blog?.category?.name || "Uncategorized"}
        </p>
        <p className="meta">Published on: {formatDate(blog.createdAt)}</p>
      </div>
      <div className="single-blog-right">
        {/* Search Bar */}
        <div className="blog-search-bar">
          <div className="search-input-container">
            <input
              className="blog-searchbar-input"
              type="text"
              placeholder="Search blogs..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <FontAwesomeIcon
              icon={faMagnifyingGlass}
              className="blog-search-icon"
            />
          </div>
        </div>

        {/* Recent Blogs Section */}
        <div className="recent-blogs">
          <h2>Recent Blogs</h2>
          <ul>
            {filteredBlogs.length > 0 ? (
              filteredBlogs.map((recentBlog) => (
                <li key={recentBlog._id}>
                  <a href={`/blog/${recentBlog.title.replace(/\s+/g, "-").toLowerCase()}`}>{recentBlog.title}</a>
                  <p>
                    <FontAwesomeIcon icon={faCalendarDays} />{" "}
                    {formatDate(recentBlog.createdAt)}
                  </p>
                </li>
              ))
            ) : (
              <p>No blogs found</p>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
