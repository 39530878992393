import React, { useState, useEffect } from "react";
import "./course.css";
import all from "../../assets/images/all.png";
import govt from "../../assets/images/govt.png";
import state from "../../assets/images/state.png";
import Posterimage from "../../assets/images/Posterimage.png";
import connect from "../../assets/images/connect.png";
import BASE_URL from "../../components/link/link";
import { Helmet } from "react-helmet";

const Course = () => {
  const [activeButton, setActiveButton] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [activeFaq, setActiveFaq] = useState(null);
  const [bannerImageUrl, setBannerImageUrl] = useState("");

  const [formData, setFormData] = useState({
    name: "",
    mobile: "",
    email: "",
    course: "",
    city: "",
    message: "",
  });
  const [courseCards, setCourseCards] = useState({});
  const [loading, setLoading] = useState(true);
  const [faqData, setFaqData] = useState([]);
  const [faqLoading, setFaqLoading] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [showModal, setShowModal] = useState(false); // For modal visibility
  const [selectedCourse, setSelectedCourse] = useState(null); // For course details in modal
  const [showPopup, setShowPopup] = useState(false);

  const navigationButtons = [
    { id: 1, title: "All Categories", logo: all },
    { id: 2, title: "Online", logo: govt },
    { id: 3, title: "Offline", logo: state },
  ];

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await fetch(`${BASE_URL}/course/all`);
        const data = await response.json();
        const groupedCourses = data.reduce((acc, course) => {
          const category = course.category.toUpperCase();
          if (!acc[category]) acc[category] = [];
          acc[category].push(course);
          return acc;
        }, {});
        groupedCourses["ALL"] = data;
        setCourseCards(groupedCourses);
      } catch (error) {
        console.error("Error fetching courses:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourses();
  }, []);

  // Fetch success banner image
  useEffect(() => {
    fetch(`${BASE_URL}/banner/courses`)
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setBannerImageUrl(data[0].imageUrl); // Assuming the response is an array
        }
      })
      .catch((error) => console.error("Error fetching banner image:", error));
  }, []);

  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const response = await fetch(`${BASE_URL}/faqs/all-faqs`);
        const data = await response.json();
        setFaqData(data);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      } finally {
        setFaqLoading(false);
      }
    };

    fetchFaqs();
  }, []);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const getFilteredCourses = () => {
    const courses = courseCards["ALL"] || []; // Consider all courses for filtering

    const sortedCourses = [...courses].sort(
      (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
    );

    return sortedCourses.filter((course) => {
      const isOnline =
        course.mode.toLowerCase() === "online" ||
        course.mode.toLowerCase() === "online/offline";
      const isOffline =
        course.mode.toLowerCase() === "offline" ||
        course.mode.toLowerCase() === "online/offline";

      const matchesMode =
        activeButton === 1 || // Show all categories for "All Categories" filter
        (activeButton === 2 && isOnline) || // Online filter
        (activeButton === 3 && isOffline); // Offline filter

      const matchesQuery =
        course.courseName.toLowerCase().includes(searchQuery.toLowerCase()) ||
        course.medium.toLowerCase().includes(searchQuery.toLowerCase());

      return matchesMode && matchesQuery; // Match both mode and search query
    });
  };

  // const handleJoinNowClick = (course) => {
  //   if (course.mode.toLowerCase() === 'online' || course.mode.toLowerCase() === 'online/offline') {
  //     window.location.href = "https://play.google.com/store/apps/details?id=co.kevin.vpupu";
  //   } else if (course.mode.toLowerCase() === 'offline') {
  //     setSelectedCourse(course);
  //     setShowModal(true);
  //   }
  // };

  const closeModal = () => {
    setShowModal(false);
    setSelectedCourse(null);
  };

  const filteredCourses = getFilteredCourses();

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitting(true);

    try {
      const response = await fetch(`${BASE_URL}/contact/submit-form`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        alert("Your message has been sent successfully!");

        setFormData({
          name: "",
          mobile: "",
          email: "",
          course: "",
          city: "",
          message: "",
        });
      } else {
        alert("There was an error submitting your form. Please try again.");
      }
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("There was an error submitting your form. Please try again.");
    } finally {
      setFormSubmitting(false);
    }
  };

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <>
      <Helmet>
        <title>
          Comprehensive Courses at Shyam Institute: Police, SSC, RRB, and More
        </title>
        <meta
          name="description"
          content="Explore our diverse range of courses tailored for aspirants aiming for roles such as Police Constables, Excise Constables, Forest Constables, RTC Constables, RRB, SSC Constables, APPSC Groups, TSPSC Groups, AP Dsc, Telangana Dsc and Communication SI. Enroll now to achieve your dream job through expert-led classes."
        />
      </Helmet>
      <div className="app-container">
        <div className="exam-result-container">
          <img src={bannerImageUrl} alt="Hero" className="exam-result-image" />
        </div>

        <div className="courses-section">
          <h2 className="section-title">
            <span style={{ color: "#043DEC" }}>Courses</span>{" "}
            <span
              style={{
                color: "#F6C300",
                textDecoration: "underline",
                textUnderlineOffset: "10px",
                textDecorationColor: "#1A45AC",
              }}
            >
              We Offered
            </span>
          </h2>

          <div className="nav-buttons">
            {navigationButtons.map((button) => (
              <button
                key={button.id}
                className={`nav-button ${
                  activeButton === button.id ? "active" : ""
                }`}
                onClick={() => {
                  setActiveButton(button.id);
                  setSearchQuery("");
                }}
              >
                <img src={button.logo} alt={button.title} />
                <span>{button.title}</span>
              </button>
            ))}
          </div>

          <div className="search-bar">
            <input
              type="text"
              placeholder={`Search in ${
                navigationButtons.find((btn) => btn.id === activeButton)
                  ?.title || "All Categories"
              }...`}
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </div>

          <div className="course-cards">
            {loading ? (
              <p>Loading courses...</p>
            ) : filteredCourses.length > 0 ? (
              filteredCourses.map((course) => (
                <div key={course._id} className="course-card">
                  <img src={course.bannerImg} alt={course.courseName} />
                  <h3>{course.courseName}</h3>
                  <p className="yellow-text">{course.medium}</p>
                  <p className="blue-text">{course.mode}</p>
                  <button
                    className="join-button"
                    onClick={() => {
                      if (
                        course.mode.toLowerCase() === "online" ||
                        course.mode.toLowerCase() === "online/offline"
                      ) {
                        window.open("https://vpupu.courses.store/", "_blank");
                      } else if (course.mode.toLowerCase() === "offline") {
                        setSelectedCourse(course); // Set the selected course details
                        setShowPopup(true); // Show the popup
                      }
                    }}
                  >
                    Join Now
                  </button>
                </div>
              ))
            ) : (
              <div className="no-results">
                <p>No courses found matching your search criteria.</p>
              </div>
            )}
          </div>
          <div className={`courses-popup ${showPopup ? "visible" : ""}`}>
            <div className="courses-popup-content">
              {selectedCourse && (
                <>
                  <h3>{selectedCourse.courseName}</h3>
                  <p>
                    <strong>Medium:</strong> {selectedCourse.medium}
                  </p>
                  <p>
                    <strong>Mode:</strong> {selectedCourse.mode}
                  </p>
                  <p>
                    <strong>Category:</strong> {selectedCourse.category}
                  </p>
                  <p>
                    <strong>Description:</strong> {selectedCourse.description}
                  </p>
                </>
              )}
              <button
                className="courses-popup-close-icon"
                onClick={() => setShowPopup(false)}
                aria-label="Close"
              >
                ×
              </button>

              <button
                className="contact-us-button"
                onClick={() => (window.location.href = "/contact")}
              >
                For more details, contact us
              </button>
            </div>
          </div>
        </div>

        <div className="faq-section">
          <h2 className="section-title">
            <span className="blue-text">Frequently Asked</span>
            <span className="yellow-text">Questions?</span>
            <div className="blue-line"></div>
          </h2>

          <div className="faq-container">
            {faqLoading ? (
              <p>Loading FAQs...</p>
            ) : faqData.length > 0 ? (
              faqData.map((faq) => (
                <div
                  key={faq._id}
                  className={`faq-item ${
                    activeFaq === faq._id ? "active" : ""
                  }`}
                  onClick={() =>
                    setActiveFaq(activeFaq === faq._id ? null : faq._id)
                  }
                >
                  <div className="faq-question">
                    <span>{faq.question}</span>
                    <span className="toggle-icon">
                      {activeFaq === faq._id ? "-" : "+"}
                    </span>
                  </div>
                  {activeFaq === faq._id && (
                    <div className="faq-answer">{faq.answer}</div>
                  )}
                </div>
              ))
            ) : (
              <p>No FAQs available at the moment.</p>
            )}
          </div>
        </div>

        <div className="course-contact-section">
          <h2>Any Inquiries About Courses, Classes? Get In Touch With Us</h2>
          <div className="course-contact-container">
            <img src={connect} alt="Contact" className="course-contact-image" />
            <form onSubmit={handleFormSubmit} className="course-contact-form">
              <input
                type="text"
                name="name"
                placeholder="Name*"
                value={formData.name}
                onChange={handleInputChange}
                required
              />
              <input
                type="tel"
                name="mobile"
                placeholder="Mobile*"
                value={formData.mobile}
                onChange={handleInputChange}
                required
                pattern="[0-9]{10}"
                title="Mobile number must be 10 digits"
              />
              <input
                type="email"
                name="email"
                placeholder="Email*"
                value={formData.email}
                onChange={handleInputChange}
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="Please enter a valid email address"
              />
              <select
                name="course"
                value={formData.course}
                onChange={handleInputChange}
                required
              >
                <option value="">Select Course*</option>
                {courseCards.ALL?.map((course) => (
                  <option key={course._id} value={course.courseName}>
                    {course.courseName}
                  </option>
                ))}
              </select>
              <input
                type="text"
                name="city"
                placeholder="City*"
                value={formData.city}
                onChange={handleInputChange}
                required
                pattern="[A-Za-z\s]+" /* Only letters and spaces are allowed */
                title="City name should only contain letters"
              />
              <textarea
                name="message"
                placeholder="Message*"
                value={formData.message}
                onChange={handleInputChange}
                required
              />
              <button
                type="submit"
                className="submit-button"
                disabled={formSubmitting}
              >
                {formSubmitting ? "Submitting..." : "Submit"}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Course;
