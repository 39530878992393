import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';
import loginbg from "../../assets/images/poster.png";
import loginlogo from "../../assets/images/slogo.png";
import BASE_URL from '../../components/link/link';

const Signup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    mobile: '',
    email: '',
    password: ''
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BASE_URL}/user/signup`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        setShowSuccess(true);
        setTimeout(() => {
          setShowSuccess(false);
          navigate('/login');
        }, 2000);
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'Signup failed. Please try again.');
      }
    } catch (error) {
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  return (
    <div className="auth-container">
      <div className="poster-image">
        <img src={loginbg} alt="Poster" />
      </div>

      <div className="auth-form-container">
        <div className="auth-form">
          <img src={loginlogo} alt="Logo" className="logo" />
          <h2>SignUp To Your Account</h2>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="name"
                placeholder="Name*"
                value={formData.name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <input
                type="text"
                name="mobile"
                placeholder="Mobile*"
                value={formData.mobile}
                onChange={handleChange}
                required
                pattern="[0-9]{10}"
                title="Mobile number must be 10 digits"
              />
            </div>

            <div className="form-group">
              <input
                type="email"
                name="email"
                placeholder="Email*"
                value={formData.email}
                onChange={handleChange}
                required
                pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                title="Please enter a valid email address"
              />
            </div>

            <div className="form-group">
              <input
                type="password"
                name="password"
                placeholder="Password*"
                value={formData.password}
                onChange={handleChange}
                required
                pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&#])[A-Za-z\d@$!%*?&#]{8,}$"
                title="Password must be at least 8 characters long, contain at least 1 special character, 1 number, 1 uppercase letter, and 1 lowercase letter."
              />
            </div>

            <button type="submit" className="submit-btn">
              Sign Up
            </button>
          </form>

          <p>
            Already have an account? <a href="/login">Login</a>
          </p>

          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>

      {showSuccess && (
        <div className="success-popup">
          Signup Successful!
        </div>
      )}
    </div>
  );
};

export default Signup;
