import React, { useState, useEffect } from "react";
import "./Subscription.css";
import { useNavigate } from "react-router-dom";
import placeholder from "../../assets/images/person.jpg";
import BASE_URL from "../../components/link/link";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Toaster } from "react-hot-toast";
import {
  faCircleNotch,
  faCircleCheck,
  faCircleExclamation,
} from "@fortawesome/free-solid-svg-icons";

function Subscription() {
  const [studentId, setStudentId] = useState("");
  const [zones, setZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState("");

  const defaultFormData = (sid) => ({
    studentId: sid,
    name: "",
    motherName: "",
    fatherName: "",
    dob: "",
    community: "",
    // sscHallTicketNo: "",
    aadharNo: "",
    registrationMobileNo: "",
    parentMobileNo: "",
    email: "",
    medium: "",
    gender: "",
    zone:"",
    cityName: "",
    centre: "",
    examName: selectedExam,
    paymentStatus: "paid",
    monthsSelected: "",
    daysSelected: "",
    exServiceman: "",
    registrationNumber: "",
  });

  const pcFormData = (sid) => ({
    studentId: sid,
    name: "",
    motherName: "",
    fatherName: "",
    dob: "",
    community: "",
    // sscHallTicketNo: "",
    aadharNo: "",
    pcMobileNo: "",
    parentMobileNo: "",
    email: "",
    medium: "",
    gender: "",
    zone:"",
    cityName: "",
    centre: "",
    examName: selectedExam,
    paymentStatus: "paid",
    monthsSelected: "",
    daysSelected: "",
    exServiceman: "",
    pcRegistrationNumber: "",
  });

  const groupIIFormData = (sid) => ({
    studentId: sid,
    name: "",
    motherName: "",
    fatherName: "",
    dob: "",
    community: "",
    // sscHallTicketNo: "",
    aadharNo: "",
    mobileNo: "",
    parentMobileNo: "",
    email: "",
    medium: "",
    gender: "",
    zone:"",
    cityName: "",
    centre: "",
    examName: selectedExam,
    paymentStatus: "paid",
    monthsSelected: "",
    daysSelected: "",
    registrationNumber: "",
  });

  // console.log(studentId);

  const [formData, setFormData] = useState({});
  // const [tokenIsValid, setTokenIsValid] = useState(false);
  const [uploadedImage, setUploadedImage] = useState(null); // State for uploaded photo
  const [uploadedPdf, setUploadedPdf] = useState(null);
  // const [hallticketFormat, setHallticketFormat] = useState("");
  const [submissionStatus, setSubmissionStatus] = useState({
    loading: false,
    success: false,
    error: null,
  });

  const navigate = useNavigate();

  // New state for exam details
  const [examDetails, setExamDetails] = useState([]);
  const [selectedExam, setSelectedExam] = useState("");
  const [availableCities, setAvailableCities] = useState([]);
  const [availableCentres, setAvailableCentres] = useState([]);
  const [availablePlans, setAvailablePlans] = useState({});
  const [hallTickets, setHallTickets] = useState([]);
  const [existingHallTicket, setExistingHallTicket] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  // const [isHallTicketGenerated, setIsHallTicketGenerated] = useState(false);
  const [validationErrors, setValidationErrors] = useState({});

  const [validHallTickets, setValidHallTickets] = useState([]);
  // Define state for selected plan
  const [selectedPlan, setSelectedPlan] = useState("");
  // const [availablePlans, setAvailablePlans] = useState({});

  useEffect(() => {
    const fetchValidHallTickets = async () => {
      if (studentId) {
        try {
          const response = await fetch(
            `${BASE_URL}/hallticket/getValidHallTicketsByStudentId/${studentId}`
          );
          const data = await response.json();
          if (data.validHallTickets) {
            setValidHallTickets(data.validHallTickets);
          }
        } catch (error) {
          console.error("Failed to fetch valid hall tickets:", error);
        }
      }
    };

    fetchValidHallTickets();
  }, [studentId]);

  // Check if selected exam exists in valid hall tickets
  const checkExistingHallTicket = (examName) => {
    return validHallTickets.find((ticket) => ticket.examName === examName);
  };

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          navigate("/login"); // Redirect to login page if not logged in
          return;
        }

        // Fetch user details and subscription status
        const response = await fetch(`${BASE_URL}/user/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setStudentId(data.user.studentId);
        } else {
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        console.error("Error fetching user status:", error);
      } finally {
        setIsLoading(false);
      }
    };

    checkUserStatus();
  }, [navigate]);

  // Fetch exam details on component mount
  useEffect(() => {
    const fetchExamDetails = async () => {
      try {
        const response = await fetch(
          `${BASE_URL}/examWithCentre/all-exam-details`
        );
        const data = await response.json();
        setExamDetails(data.exams);
      } catch (error) {
        console.error("Failed to fetch exam details:", error);
      }
    };

    fetchExamDetails();
  }, []);

  // Fetch zones from API
  useEffect(() => {
    const fetchZones = async () => {
      try {
        const response = await fetch(`${BASE_URL}/zones/all-zones`);
        const data = await response.json();
        setZones(data); // Store zones in state
      } catch (error) {
        console.error("Failed to fetch zones:", error);
      }
    };

    fetchZones();
  }, []);

  // Update cities when exam is selected
  useEffect(() => {
    if (selectedExam) {
      const selectedExamData = examDetails.find(
        (exam) => exam.examName === selectedExam
      );
      if (selectedExamData) {
        setAvailableCities(selectedExamData.cities);
        setAvailablePlans(selectedExamData.prices);
      }
    } else {
      setAvailableCities([]);
      setAvailablePlans({});
    }
  }, [selectedExam, examDetails]);

  const validationLengths = {
    DSC: 11,
    SSC: 11,
    "GROUP 2": 9,
  };

  const getMaxLength = (exam) => {
    const upperExam = exam.toUpperCase();
    for (const key in validationLengths) {
      if (upperExam.includes(key)) {
        return validationLengths[key];
      }
    }
    return 10; // Default max length if no match
  };

  const maxLength = getMaxLength(selectedExam);

  // Update centres when city is selected
  const handleCityChange = (cityName) => {
    const selectedCity = availableCities.find(
      (city) => city.cityName === cityName
    );
    if (selectedCity) {
      setAvailableCentres(selectedCity.centres);
    } else {
      setAvailableCentres([]);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));

    // Log when input is being changed
    // console.log(`Changed field: ${name} with value: ${value}`);

    // Clear the validation error for this field
    setValidationErrors((prev) => {
      const updatedErrors = { ...prev };
      delete updatedErrors[name];
      return updatedErrors;
    });

    // Special handling for city change
    if (name === "cityName") {
      handleCityChange(value);
    }
  };

  // New function to handle subscription plan selection
  const handlePlanChange = (event) => {
    const selectedPlan = event.target.value;

    // Reset previous selections
    setFormData((prevData) => ({
      ...prevData,
      monthsSelected: "",
      daysSelected: "",
    }));

    // Parse the plan to determine months or days
    const planParts = selectedPlan.split(" - ");
    const planValue = planParts[0];
    const price = availablePlans[selectedPlan]; // Fetch price from availablePlans

    // Check if price exists, if not default to 0
    if (price === undefined) {
      console.error("Price not found for the selected plan");
    }

    // Update formData with the selected plan and price
    setFormData((prevData) => ({
      ...prevData,
      subscriptionPlan: selectedPlan, // Store selected plan
      price: price || 0, // Store price of the selected plan (amount)
      monthsSelected: "", // Reset months selection
      daysSelected: "", // Reset days selection
    }));

    // Handle months or days selection based on the plan value
    if (planValue.includes("months")) {
      // If plan contains 'months', set monthsSelected
      setFormData((prevData) => ({
        ...prevData,
        monthsSelected: planValue.replace(" months", ""),
        daysSelected: "",
      }));
    } else if (planValue.includes("days")) {
      // If plan contains 'days', set daysSelected
      setFormData((prevData) => ({
        ...prevData,
        daysSelected: planValue.replace(" days", ""),
        monthsSelected: "",
      }));
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = URL.createObjectURL(file); // Create a temporary URL for the uploaded image
      setUploadedImage(imageUrl);
    }
  };

  const clearUploadedImage = () => {
    setUploadedImage(null);
    // If there's a file input, reset its value
    const fileInput = document.querySelector('input[type="file"]');
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handlePdfUpload = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "application/pdf") {
      const pdfUrl = URL.createObjectURL(file);
      setUploadedPdf(pdfUrl); // Set the uploaded PDF URL for preview
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const clearUploadedPdf = () => {
    setUploadedPdf(null); // Clear the uploaded PDF
  };

  const formatOptions = ["Yes", "No"];

  // Update formData structure based on the selected exam
  useEffect(() => {
    if (selectedExam.toUpperCase().includes("GROUP 2")) {
      setFormData(groupIIFormData(studentId));
    } else if (selectedExam.toUpperCase().includes("PC")) {
      setFormData(pcFormData(studentId));
    } else {
      setFormData(defaultFormData(studentId));
    }
  }, [selectedExam, studentId]);

  // New submit handler
  const handleSubmit = async (e) => {
    // Proceed with submission logic
    setSubmissionStatus({ loading: true, success: false, error: null });

    try {
      const formDataToSubmit = new FormData();

      // Append all non-empty form fields
      Object.keys(formData).forEach((key) => {
        if (formData[key] !== "") {
          formDataToSubmit.append(key, formData[key]);
        }
      });

      // Log form fields
      // console.log("Form Fields:", formData);

      // Handle image upload
      const imageFile = await fetch(uploadedImage).then((r) => r.blob());
      formDataToSubmit.append("studentImage", imageFile, "student-photo.jpg");

      // Handle PDF upload
      const pdfFile = await fetch(uploadedPdf).then((r) => r.blob());
      formDataToSubmit.append(
        "registrationForm",
        pdfFile,
        "registration-form.pdf"
      );

      // API route selection
      let route;
      route = `${BASE_URL}/hallticket/generateHallTicket`;
      // route ="https://backends-gg7k.onrender.com/hallticket/generateHallTicket";

      const response = await fetch(route, {
        method: "POST",
        body: formDataToSubmit,
      });

      if (response.ok) {
        const result = await response.json();
        toast(
          ({ closeToast: closeSuccessToast }) => (
            <div style={{ textAlign: "center" }}>
              <p><strong>HallTicket generated successfully! Please Collect Your Hallticket at your Exam Centers Only on Sundays</strong>
              </p>
              <button
                onClick={() => {
                  closeSuccessToast();
                  // Execute the refresh logic only for the success toast
                  setTimeout(() => {
                    window.scrollTo(0, 0);
                    window.location.reload();
                  }, 500);
                }}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#4caf50",
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                OK
              </button>
            </div>
          ),
          {
            position: "top-center",
            autoClose: false, // No auto-close
            closeOnClick: false,
            draggable: false,
            closeButton: false, // Hide default close button
          }
        );

        setSubmissionStatus({
          loading: false,
          success: true,
          error: null,
        });

        // Reset form fields
        if (selectedExam.toUpperCase().includes("GROUP 2")) {
          setFormData(groupIIFormData(studentId));
        } else if (selectedExam.toUpperCase().includes("PC")) {
          setFormData(pcFormData(studentId));
        } else {
          setFormData(defaultFormData(studentId));
        }

        // Reset other state variables
        setUploadedImage(null);
        setUploadedPdf(null);
        setSelectedExam("");
        setAvailableCities([]);
        setAvailableCentres([]);
        setAvailablePlans({});

        // console.log("Hall Ticket Generated", result);
        // setTimeout(() => {
        //   window.scrollTo(0, 0);
        //   window.location.reload();
        // }, 3000);
      } else {
        const errorData = await response.json();
        toast(
          ({ closeToast: closeFailedToast }) => (
            <div style={{ textAlign: "center" }}>
              <p>{errorData.message || "Submission failed"}</p>
              <button
                onClick={closeFailedToast}
                style={{
                  padding: "8px 16px",
                  backgroundColor: "#f44336", // Red color for error
                  color: "white",
                  border: "none",
                  borderRadius: "4px",
                  cursor: "pointer",
                  marginTop: "10px",
                }}
              >
                OK
              </button>
            </div>
          ),
          {
            position: "top-center",
            autoClose: false,
            closeOnClick: false,
            draggable: false,
            closeButton: false,
          }
        );
        setSubmissionStatus({
          loading: false,
          success: false,
          error: errorData.message || "Submission failed",
        });
      }
    } catch (error) {
      toast(
        ({ closeToast: closeErrorToast }) => (
          <div style={{ textAlign: "center" }}>
            <p>An error occurred during submission.</p>
            <button
              onClick={closeErrorToast}
              style={{
                padding: "8px 16px",
                backgroundColor: "#f44336", // Red color for error
                color: "white",
                border: "none",
                borderRadius: "4px",
                cursor: "pointer",
                marginTop: "10px",
              }}
            >
              OK
            </button>
          </div>
        ),
        {
          position: "top-center",
          autoClose: false,
          closeOnClick: false,
          draggable: false,
          closeButton: false,
        }
      );
      setSubmissionStatus({
        loading: false,
        success: false,
        error: error.message,
      });
    }
  };

  // Render submission status messages
  const renderSubmissionStatus = () => {
    if (submissionStatus.loading) {
      return (
        <>
          <div className="overlay"></div>
          <div className="status-box">
            <FontAwesomeIcon
              icon={faCircleNotch}
              className="status-box-icon fa-circle-notch"
            />
            <div className="status-box-message">
              Generating Hall Ticket... Please wait
            </div>
          </div>
        </>
      );
    }

    if (submissionStatus.success) {
      return (
        <>
          <div className="overlay"></div>
          <div className="status-box">
            <FontAwesomeIcon
              icon={faCircleCheck}
              className="status-box-icon fa-circle-check"
            />
            <div className="status-box-message">
              Hall Ticket Generated Successfully!
            </div>
          </div>
        </>
      );
    }

    if (submissionStatus.error) {
      return (
        <>
          <div className="status-box-overlay"></div>
          <div className="status-box">
            <FontAwesomeIcon
              icon={faCircleExclamation}
              className="status-box-icon fa-circle-exclamation"
            />
            <div className="status-box-message">{submissionStatus.error}</div>
          </div>
        </>
      );
    }
    return null;
  };

  // const handleSelectChange = (e) => {
  //   const selectedValue = e.target.value;

  //   // Check if the user is logged in
  //   if (!localStorage.getItem("token")) {
  //     // If not logged in, navigate to login page when an option is selected
  //     if (selectedValue) {
  //       navigate("/login");
  //     }
  //   } else {
  //     // If logged in, update the state with selected value
  //     setHallticketFormat(selectedValue);
  //   }
  // };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const handlePayment = async (amount) => {
    // console.log(amount);
    try {
      const res = await fetch(`${BASE_URL}/payment/order`, {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        body: JSON.stringify({
          amount, // Pass the amount (price) to the backend
        }),
      });

      const data = await res.json();
      // console.log(data);
      // Call handlePaymentVerify with the response data
      return handlePaymentVerify(data.data);
    } catch (error) {
      // console.log(error);
      alert("something went wrong please fill all details again and try..");
      window.location.reload();
      throw error; // Prevent further execution if payment fails
    }
  };

  // handlePaymentVerify Function remains the same
  const handlePaymentVerify = (data) => {
    return new Promise((resolve, reject) => {
      const options = {
        key: "rzp_live_sFc64UI72d3NUw",
        amount: data.amount,
        currency: data.currency,
        name: "Shyam Institute",
        description: "Test Mode",
        order_id: data.id,
        handler: async (response) => {
          // console.log("response", response);
          try {
            const res = await fetch(`${BASE_URL}/payment/verify`, {
              method: "POST",
              headers: {
                "content-type": "application/json",
              },
              body: JSON.stringify({
                razorpay_order_id: response.razorpay_order_id,
                razorpay_payment_id: response.razorpay_payment_id,
                razorpay_signature: response.razorpay_signature,
              }),
            });

            const verifyData = await res.json();

            if (verifyData.message) {
              // console.log(verifyData.message);
              toast.success(verifyData.message);
              alert("Payment Successful");
              resolve(); // Resolve after successful verification
            } else {
              reject(new Error("Payment verification failed"));
              alert("Payment Failed");
            }
          } catch (error) {
            // console.log(error);
            alert("Something Went Wrong");
            reject(error); // Reject if there was an error
          }
        },
        theme: {
          color: "#5f63b8",
        },
      };
      const rzp1 = new window.Razorpay(options);
      rzp1.open();
    });
  };

  return (
    <section className="subscription-section">
      <div className="subscription-heading">
        <h2>
          <span style={{ color: "#1A45AC" }}>Download your</span>{" "}
          <span
            style={{
              color: "#F6C300",
              textDecoration: "underline",
              textUnderlineOffset: "10px",
              textDecorationColor: "#1A45AC",
            }}
          >
            Hall Ticket
          </span>
        </h2>
      </div>

      <div className="hallticket-form-container">
        <label>
          <span className="heading">
            Exam Name:<span className="required">*</span>
          </span>
          <select
            name="examName"
            value={selectedExam}
            onChange={(e) => {
              setSelectedExam(e.target.value);
              handleChange(e);
            }}
          >
            <option value="">Select Exam</option>
            {examDetails.map((exam, index) => (
              <option key={index} value={exam.examName}>
                {exam.examName}
              </option>
            ))}
          </select>
        </label>

        {selectedExam && checkExistingHallTicket(selectedExam) ? (
          <div className="existing-hall-tickets">
            <h3>You already have a valid hall ticket for this exam:</h3>
            <p>
              <strong>Hall Ticket Number:</strong>{" "}
              {checkExistingHallTicket(selectedExam).hallTicketNumber}
            </p>
            <p>
              <strong>Exam Name:</strong>{" "}
              {checkExistingHallTicket(selectedExam).examName}
            </p>
            <p>
              <strong>Valid Until:</strong>{" "}
              {checkExistingHallTicket(selectedExam).expiryDate}
            </p>
          </div>
        ) : (
          selectedExam && (
            <>
              <div className="subscription-hallticket-body">
                {/* Form Fields 1 */}
                <div className=" subscription-hallticket-column">
                  <label className="subscription-hidden-label">
                    <span>&nbsp;</span>
                  </label>{" "}
                  {/* Dummy label */}
                  <label>
                    <span className="heading">
                      Name of the Candidate:<span className="required">*</span>
                    </span>
                    <input
                      type="text"
                      name="name"
                      value={formData.name}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        // Allow only alphabets and spaces
                        if (!/^[a-zA-Z\s]$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        // Remove any non-alphabetic characters
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        );
                      }}
                      required
                      className={validationErrors.name ? "error" : ""}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <span className="heading">
                      Father’s Name:<span className="required">*</span>
                    </span>
                    <input
                      type="text"
                      name="fatherName"
                      value={formData.fatherName}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        // Allow only alphabets and spaces
                        if (!/^[a-zA-Z\s]$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        // Remove any non-alphabetic characters
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        );
                      }}
                      required
                      className={validationErrors.fatherName ? "error" : ""}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <span className="heading">
                      Date of Birth:<span className="required">*</span>
                    </span>
                    <input
                      type="date"
                      name="dob"
                      value={formData.dob}
                      onChange={handleChange}
                      required
                      className={validationErrors.dob ? "error" : ""}
                      autocomplete="off"
                      min="1900-01-01"
                      max="2099-12-31" 
                    />
                  </label>
                  {/* <label>
                    <span className="heading">
                      SSC H T No:<span className="required">*</span>
                    </span>
                    <input
                      type="text"
                      name="sscHallTicketNo"
                      value={formData.sscHallTicketNo}
                      onChange={(e) => {
                        // Remove any non-numeric characters and limit input to 10 digits
                        const numericValue = e.target.value.replace(/\D/g, "").slice(0, 10);
                        handleChange({ target: { name: e.target.name, value: numericValue } });
                      }}
                      onKeyPress={(e) => {
                        // Allow only digits (0-9)
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      maxLength={10} // Ensures max input length at HTML level
                      required
                      className={validationErrors.sscHallTicketNo ? "error" : ""}
                      autoComplete="off"
                      placeholder="Enter 10-digit SSC H T No"
                    />
                  </label> */}
                  {selectedExam.toUpperCase().includes("PC") && (
                    <label>
                      <span className="heading">
                        PC Register Mobile No:
                        <span className="required">*</span>
                      </span>
                      <input
                        type="text"
                        name="pcMobileNo"
                        value={formData.pcMobileNo}
                        onChange={(e) => {
                          // Allow only numeric characters and enforce max length of 10 digits
                          const numericValue = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 10);
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: numericValue,
                            },
                          });
                        }}
                        onKeyPress={(e) => {
                          // Allow only digits (0-9)
                          if (!/^\d$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={10} // HTML attribute to limit length
                        required
                        className={validationErrors.pcMobileNo ? "error" : ""}
                        autoComplete="off"
                        placeholder="Enter 10-digit mobile number"
                      />
                    </label>
                  )}
                  {!selectedExam.toUpperCase().includes("PC") &&
                    !selectedExam.toUpperCase().includes("GROUP 2") && (
                      <label>
                        <span className="heading">
                          Register Mobile No:
                          <span className="required">*</span>
                        </span>
                        <input
                          type="text"
                          name="registrationMobileNo"
                          value={formData.registrationMobileNo}
                          onChange={(e) => {
                            // Allow only numeric characters and enforce max length of 10 digits
                            const numericValue = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 10);
                            handleChange({
                              target: {
                                name: e.target.name,
                                value: numericValue,
                              },
                            });
                          }}
                          onKeyPress={(e) => {
                            // Allow only digits (0-9)
                            if (!/^\d$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={10} // HTML attribute to limit length
                          required
                          className={
                            validationErrors.registrationMobileNo ? "error" : ""
                          }
                          autoComplete="off"
                          placeholder="Enter 10-digit mobile number"
                        />
                      </label>
                    )}
                  {!selectedExam.toUpperCase().includes("GROUP 2") && (
                    <>
                      <label>
                        <span className="heading">
                          Parent Mobile No:<span className="required">*</span>
                        </span>
                        <input
                          type="text"
                          name="parentMobileNo"
                          value={formData.parentMobileNo}
                          onChange={(e) => {
                            // Allow only numeric characters and limit input to 10 digits
                            const numericValue = e.target.value
                              .replace(/\D/g, "")
                              .slice(0, 10);
                            handleChange({
                              target: {
                                name: e.target.name,
                                value: numericValue,
                              },
                            });
                          }}
                          onKeyPress={(e) => {
                            // Allow only digits (0-9)
                            if (!/^\d$/.test(e.key)) {
                              e.preventDefault();
                            }
                          }}
                          maxLength={10} // HTML attribute to ensure max length
                          required
                          className={
                            validationErrors.parentMobileNo ? "error" : ""
                          }
                          autoComplete="off"
                          placeholder="Enter 10-digit mobile number"
                        />
                      </label>
                      <label>
                        <span className="heading">
                          Ex-Servicemen:<span className="required">*</span>
                        </span>
                        <select
                          name="exServiceman"
                          value={formData.exServiceman}
                          onChange={handleChange}
                          required
                          className={
                            validationErrors.exServiceman ? "error" : ""
                          }
                        >
                          <option value="">Select</option>
                          <option value="Yes">Yes</option>
                          <option value="No">No</option>
                        </select>
                      </label>
                    </>
                  )}
                  {selectedExam.toUpperCase().includes("GROUP 2") && (
                    <label>
                      <span className="heading">
                        Candidate Mobile:<span className="required">*</span>
                      </span>
                      <input
                        type="text"
                        name="mobileNo"
                        value={formData.mobileNo}
                        onChange={(e) => {
                          // Remove non-numeric characters and limit input to 10 digits
                          const numericValue = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 10);
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: numericValue,
                            },
                          });
                        }}
                        onKeyPress={(e) => {
                          // Allow only digits (0-9)
                          if (!/^\d$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        maxLength={10} // HTML-level enforcement
                        required
                        className={validationErrors.mobileNo ? "error" : ""}
                        autoComplete="off"
                        placeholder="Enter 10-digit mobile number"
                      />
                    </label>
                  )}
                  <label>
                    <span className="heading">
                      Email ID:<span className="required">*</span>
                    </span>
                    <input
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={(e) => {
                        const emailPattern =
                          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
                        handleChange(e);

                        // Check email validity and update validation state
                        if (!emailPattern.test(e.target.value)) {
                          setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            email: "Please enter a valid email address",
                          }));
                        } else {
                          setValidationErrors((prevErrors) => ({
                            ...prevErrors,
                            email: "",
                          }));
                        }
                      }}
                      required
                      className={validationErrors.email ? "error" : ""}
                      autoComplete="off"
                      placeholder="example@example.com"
                    />
                    {validationErrors.email && (
                      <p className="error-message">{validationErrors.email}</p>
                    )}
                  </label>
                  <label>
                    <span className="heading">
                      Medium:<span className="required">*</span>
                    </span>
                    <select
                      name="medium"
                      value={formData.medium}
                      onChange={handleChange}
                      required
                      className={validationErrors.medium ? "error" : ""}
                    >
                      <option value="">Select</option>
                      <option value="TM">Telugu Medium</option>
                      <option value="EM">English Medium</option>
                    </select>
                  </label>
                  <label>
                    <span className="heading">
                      Gender:<span className="required">*</span>
                    </span>
                    <select
                      name="gender"
                      value={formData.gender}
                      onChange={handleChange}
                      required
                      className={validationErrors.gender ? "error" : ""}
                      autocomplete="off"
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Other">Other</option>
                    </select>
                  </label>
                </div>

                {/* Form Fields 2 */}
                <div className="subscription-hallticket-column">
                  <label className="subscription-hidden-label">
                    <span>&nbsp;</span>
                  </label>{" "}
                  {/* Dummy label */}
                  <label>
                    <span className="heading">
                      Mother’s Name:<span className="required">*</span>
                    </span>
                    <input
                      type="text"
                      name="motherName"
                      value={formData.motherName}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        // Allow only alphabets and spaces
                        if (!/^[a-zA-Z\s]$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        // Remove any non-alphabetic characters
                        e.target.value = e.target.value.replace(
                          /[^a-zA-Z\s]/g,
                          ""
                        );
                      }}
                      required
                      className={validationErrors.motherName ? "error" : ""}
                      autoComplete="off"
                    />
                  </label>
                  <label>
                    <span className="heading">
                      Community:<span className="required">*</span>
                    </span>
                    <select
                      name="community"
                      value={formData.community}
                      onChange={handleChange}
                      required
                      className={validationErrors.community ? "error" : ""}
                    >
                      <option value="">Select your community</option>z
                      <option value="SC">SC</option>
                      <option value="ST">ST</option>
                      <option value="BC-A">BC-A</option>
                      <option value="BC-B">BC-B</option>
                      <option value="BC-C">BC-C</option>
                      <option value="BC-D">BC-D</option>
                      <option value="BC-E">BC-E</option>
                      <option value="OC">OC</option>
                      <option value="EWS">EWS</option>
                      <option value="OTHERS">OTHERS</option>
                    </select>
                  </label>
                  <label>
                    <span className="heading">Aadhar No:</span>
                    <input
                      type="text"
                      name="aadharNo"
                      value={formData.aadharNo}
                      onChange={handleChange}
                      onKeyPress={(e) => {
                        // Allow only numbers to be entered
                        if (!/^\d$/.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      onInput={(e) => {
                        // Limit input length to 10 characters
                        e.target.value = e.target.value
                          .replace(/\D/g, "")
                          .slice(0, 12);
                      }}
                      required
                      maxLength={12} // Ensures a maximum of 12 characters
                      className={validationErrors.aadharNo ? "error" : ""}
                      autocomplete="off"
                      placeholder="Enter 12-digit aadhaar number"
                    />
                  </label>
                  {selectedExam.toUpperCase().includes("PC") && (
                    <label>
                      <span className="heading">
                        PC Registration No:<span className="required">*</span>
                      </span>
                      <div
                        className="registration-label"
                        style={{ flex: "1 1" }}
                      >
                        <input
                          style={{ width: "100%", boxSizing: "border-box" }}
                          type="text"
                          name="pcRegistrationNumber"
                          value={formData.pcRegistrationNumber}
                          onChange={handleChange}
                          required
                          maxLength={7}
                          className={
                            validationErrors.pcRegistrationNumber ? "error" : ""
                          }
                          autocomplete="off"
                          inputMode="numeric"
                          pattern="[0-9]*"
                          onKeyDown={(e) => {
                            if (
                              !/^\d$/.test(e.key) &&
                              e.key !== "Backspace" &&
                              e.key !== "Tab"
                            ) {
                              e.preventDefault();
                            }
                          }}
                        />
                        <p
                          className="input-hint"
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (Please Enter Your 7 Digits PC Registration Number)
                        </p>
                      </div>
                    </label>
                  )}
                  {!selectedExam.toUpperCase().includes("PC") && (
                    <label>
                      <span className="heading">
                        Registration No:<span className="required">*</span>
                      </span>
                      <div
                        className="registration-label"
                        style={{ flex: "1 1" }}
                      >
                        <input
                          style={{ width: "100%", boxSizing: "border-box" }}
                          type="text"
                          name="registrationNumber"
                          value={formData.registrationNumber}
                          onChange={handleChange}
                          required
                          maxLength={maxLength}
                          className={
                            validationErrors.registrationNumber ? "error" : ""
                          }
                          autoComplete="off"
                        />
                        <p
                          className="input-hint"
                          style={{
                            margin: "0",
                            fontSize: "12px",
                            color: "red",
                          }}
                        >
                          (Please Enter Your {maxLength} Digits Registration
                          Number)
                        </p>
                      </div>
                    </label>
                  )}
                  {selectedExam.toUpperCase().includes("GROUP 2") && (
                    <label>
                      <span className="heading">
                        Parent Mobile No:<span className="required">*</span>
                      </span>
                      <input
                        type="text"
                        name="parentMobileNo"
                        value={formData.parentMobileNo}
                        onChange={(e) => {
                          // Allow only numeric input and limit to 10 digits
                          const numericValue = e.target.value
                            .replace(/\D/g, "")
                            .slice(0, 10);
                          handleChange({
                            target: {
                              name: e.target.name,
                              value: numericValue,
                            },
                          });
                        }}
                        onKeyPress={(e) => {
                          // Allow only numeric characters (0-9)
                          if (!/^\d$/.test(e.key)) {
                            e.preventDefault();
                          }
                        }}
                        required
                        maxLength={10} // Enforce max length in the input field
                        className={
                          validationErrors.parentMobileNo ? "error" : ""
                        }
                        autoComplete="off"
                        placeholder="Enter 10-digit mobile number"
                      />
                    </label>
                  )}
                  {/* New dropdown for Exam Name */}
                  <label>
                    <span className="heading">
                      Exam Name:<span className="required">*</span>
                    </span>
                    <select
                      name="examName"
                      value={selectedExam}
                      onChange={(e) => {
                        setSelectedExam(e.target.value);
                        handleChange(e);
                      }}
                      // disabled={selectedExam}
                      required
                      disabled
                    >
                      <option value="">Select Exam</option>
                      {examDetails.map((exam, index) => (
                        <option key={index} value={exam.examName}>
                          {exam.examName}
                        </option>
                      ))}
                    </select>
                  </label>
                  {/* Zones Dropdown */}
                  <label>
                    <span className="heading">
                      District/Zone:<span className="required">*</span>
                    </span>
                    <select
                      name="zone"
                      value={formData.zone}
                      onChange={handleChange}
                      disabled={!selectedExam} // Disable dropdown if no exam is selected
                      required
                      className={validationErrors.zone ? "error" : ""}
                    >
                      <option value="">Select District/Zone</option>
                      {zones.map((zone, index) => (
                        <option key={index} value={zone.name}>
                          {zone.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  {/* Dropdown for City */}
                  <label>
                    <span className="heading">
                      City:<span className="required">*</span>
                    </span>
                    <select
                      name="cityName"
                      value={formData.cityName}
                      onChange={handleChange}
                      disabled={!selectedExam}
                      required
                      className={validationErrors.cityName ? "error" : ""}
                    >
                      <option value="">Select City</option>
                      {availableCities.map((city, index) => (
                        <option key={index} value={city.cityName}>
                          {city.cityName}
                        </option>
                      ))}
                    </select>
                  </label>
                  {/* Dropdown for Centre */}
                  <label>
                    <span className="heading">
                      Centre:<span className="required">*</span>
                    </span>
                    <select
                      name="centre"
                      value={formData.centre}
                      onChange={handleChange}
                      disabled={!formData.cityName}
                      required
                      className={validationErrors.centre ? "error" : ""}
                    >
                      <option value="">Select Centre</option>
                      {availableCentres.map((centre, index) => (
                        <option key={index} value={centre.name}>
                          {centre.name}
                        </option>
                      ))}
                    </select>
                  </label>
                  {/* Dropdown for Subscription Plan */}
                  <label>
                    <span className="heading">
                      Subscription Plan:<span className="required">*</span>
                    </span>
                    <select
                      name="subscriptionPlan"
                      onChange={handlePlanChange}
                      disabled={!selectedExam}
                      required
                      className={
                        validationErrors.subscriptionPlan ? "error" : ""
                      }
                    >
                      <option value="">Select Plan</option>
                      {Object.entries(availablePlans).map(
                        ([plan, price], index) => (
                          <option key={index} value={plan}>
                            {plan} - ₹{price}
                          </option>
                        )
                      )}
                    </select>
                  </label>
                  {/* Debug information (optional) */}
                  {formData.monthsSelected && (
                    <div>Months Selected: {formData.monthsSelected}</div>
                  )}
                  {formData.daysSelected && (
                    <div>Days Selected: {formData.daysSelected}</div>
                  )}
                </div>

                {/* Form image */}
                <div className="subscription-hallticket-column subscription-files-column">
                  <div className="subscription-pdf-column">
                    <span>
                      Upload your Registration Form:
                      <span className="required">*</span>
                    </span>
                    <div className="subscription-pdf-column">
                      <div className="pdf-upload" style={{ display: "flex" }}>
                        {/* <span className="heading">
                          Registration Form:
                          <span className="required">*</span>
                        </span> */}
                        <input
                          type="file"
                          accept="application/pdf"
                          onChange={handlePdfUpload}
                          required
                        />
                      </div>
                      {/* {uploadedPdf && (
                        <div>
                          <p>Uploaded PDF:</p>
                          <iframe
                            src={uploadedPdf}
                            title="Uploaded PDF"
                            width="100%"
                            height="200px"
                          />
                          <button onClick={clearUploadedPdf}>Clear PDF</button>
                        </div>
                      )} */}
                      <p
                        style={{
                          color: "red",
                          fontSize: "12px",
                          marginTop: "5px",
                        }}
                      >
                        If you do not upload properly, your access will be
                        canceled.
                      </p>
                    </div>
                    {/* <div className="subscription-pdf-container">
                      {uploadedPdf ? (
                        <div className="subscription-pdf-preview">
                          <embed
                            src={uploadedPdf}
                            type="application/pdf"
                            width="100%"
                            height="200px"
                          />
                          <button
                            type="button"
                            className="subscription-clear-pdf-button"
                            onClick={clearUploadedPdf}
                          >
                            Clear
                          </button>
                        </div>
                      ) : (
                        <label className="subscription-custom-file-button">
                          Choose File
                          <input
                            type="file"
                            accept="application/pdf"
                            onChange={handlePdfUpload}
                            style={{ display: "none" }} // Hide the default file input
                          />
                        </label>
                      )}
                    </div>
                    <p
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "5px",
                      }}
                    >
                      If you do not upload properly, your access will be
                      canceled.
                    </p> */}
                  </div>
                  <div className="subscription-photo-column">
                    <p>
                      Upload your Profile Photo:
                      <span className="required">*</span>
                    </p>
                    <div className="subscription-photo-container">
                      {uploadedImage ? (
                        <img
                          src={uploadedImage}
                          alt="Uploaded"
                          className="subscription-profile-photo"
                        />
                      ) : (
                        <div className="subscription-placeholder-icon">
                          <img
                            src={placeholder} // Replace with the actual URL of the placeholder image or a local asset
                            alt="Profile Icon"
                            className="subscription-profile-placeholder-img"
                          />
                        </div>
                      )}
                    </div>

                    {uploadedImage ? (
                      <button
                        type="button"
                        className="subscription-clear-photo-button"
                        onClick={clearUploadedImage}
                      >
                        Clear
                      </button>
                    ) : (
                      <label className="subscription-custom-file-button">
                        Choose File
                        <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                        />
                      </label>
                    )}
                  </div>
                </div>
              </div>

              <div className="subscription-hallticket-footer">
                <button
                  onClick={async (e) => {
                    e.preventDefault();

                    let validationErrors = {};

                    // console.log("Form data before validation:", formData);

                    // Validate based on `selectedExam`
                    if (selectedExam.toUpperCase().includes("GROUP 2")) {
                      [
                        "name",
                        "motherName",
                        "fatherName",
                        "email",
                        // "examName",
                        "medium",
                        "dob",
                        "community",
                        // "sscHallTicketNo",
                        "mobileNo",
                        "parentMobileNo",
                        "gender",
                        "registrationNumber",
                        "zone",
                        "cityName",
                        "centre",
                        "subscriptionPlan",
                      ].forEach((field) => {
                        if (!formData[field]) {
                          validationErrors[field] = true;
                          // console.log(`${field} is required but missing`);
                        } else {
                          // Additional length validation for specific fields
                          // if (["parentMobileNo", "mobileNo", "sscHallTicketNo"].includes(field) && formData[field].length !== 10) {
                          if (
                            ["parentMobileNo", "mobileNo"].includes(field) &&
                            formData[field].length !== 10
                          ) {
                            validationErrors[field] = true;
                            // console.log(`${field} must be exactly 10 digits.`);
                          }

                          if (
                            field === "registrationNumber" &&
                            formData.registrationNumber.length !== maxLength
                          ) {
                            validationErrors[field] = true;
                            console.log(
                              `${field} must be exactly ${maxLength} digits.`
                            );
                          }
                        }
                      });
                    } else if (selectedExam.toUpperCase().includes("PC")) {
                      [
                        "name",
                        "motherName",
                        "fatherName",
                        "email",
                        // "examName",
                        "medium",
                        "dob",
                        "community",
                        // "sscHallTicketNo",
                        "pcMobileNo",
                        "parentMobileNo",
                        "gender",
                        "exServiceman",
                        "pcRegistrationNumber",
                        "zone",
                        "cityName",
                        "centre",
                        "subscriptionPlan",
                      ].forEach((field) => {
                        if (!formData[field]) {
                          validationErrors[field] = true;
                          // console.log(`${field} is required but missing`);
                        } else {
                          // Additional length validation for specific fields
                          // if (["parentMobileNo", "mobileNo", "sscHallTicketNo", "pcMobileNo"].includes(field) &&formData[field].length !== 10) {
                          if (
                            [
                              "parentMobileNo",
                              "mobileNo",
                              "pcMobileNo",
                            ].includes(field) &&
                            formData[field].length !== 10
                          ) {
                            validationErrors[field] = true;
                            // console.log(`${field} must be exactly 10 digits.`);
                          }

                          if (
                            field === "pcRegistrationNumber" &&
                            formData.pcRegistrationNumber.length !== 7
                          ) {
                            validationErrors[field] = true;
                            // console.log(
                            //   "PC Registration Number must be exactly 7 characters."
                            // );
                          }
                        }
                      });
                    } else {
                      [
                        "name",
                        "motherName",
                        "fatherName",
                        "email",
                        // "examName",
                        "medium",
                        "dob",
                        "community",
                        // "sscHallTicketNo",
                        "registrationMobileNo",
                        "parentMobileNo",
                        "gender",
                        "exServiceman",
                        "registrationNumber",
                        "zone",
                        "cityName",
                        "centre",
                        "subscriptionPlan",
                      ].forEach((field) => {
                        if (!formData[field]) {
                          validationErrors[field] = true;
                          // console.log(`${field} is required but missing`);
                        } else {
                          // Additional length validation for specific fields
                          // if (["parentMobileNo", "mobileNo", "sscHallTicketNo", "pcMobileNo"].includes(field) &&formData[field].length !== 10) {
                          if (
                            [
                              "parentMobileNo",
                              "mobileNo",
                              "registrationMobileNo",
                            ].includes(field) &&
                            formData[field].length !== 10
                          ) {
                            validationErrors[field] = true;
                            // console.log(`${field} must be exactly 10 digits.`);
                          }

                          if (
                            field === "registrationNumber" &&
                            formData.registrationNumber.length !== maxLength
                          ) {
                            validationErrors[field] = true;
                            console.log(
                              `${field} must be exactly ${maxLength} digits.`
                            );
                          }
                        }
                      });
                    }

                    // console.log("Validation errors:", validationErrors);

                    // If validation fails, show toast and return
                    if (Object.keys(validationErrors).length > 0) {
                      const errorFields = Object.keys(validationErrors)
                        .map((field) => field.replace(/([A-Z])/g, " $1").trim()) // Format camelCase to spaced text
                        .join(", ");

                      setValidationErrors(validationErrors); // Update state
                      toast.error(
                        <span>
                        <strong>Please fill all required fields!</strong> {errorFields}
                      </span>,
                        {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                          style: { width: "500px" }
                        }
                      );
                      return;
                    }

                    // Validate uploaded image
                    if (!uploadedImage) {
                      toast.error(
                        "Photo is required to generate the Hall Ticket.",
                        {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                      setSubmissionStatus({
                        loading: false,
                        success: false,
                        error: null,
                      });
                      return;
                    }

                    if (!uploadedPdf) {
                      toast.error(
                        "Pdf is required to generate the Hall Ticket.",
                        {
                          position: "top-center",
                          autoClose: 3000,
                          hideProgressBar: false,
                          closeOnClick: true,
                          pauseOnHover: true,
                          draggable: true,
                          progress: undefined,
                        }
                      );
                      setSubmissionStatus({
                        loading: false,
                        success: false,
                        error: null,
                      });
                      return;
                    }

                    setValidationErrors({});

                    // console.log(FormData);
                    try {
                      const price = formData.price; // Assuming formData contains the price field
                      // // console.log("Price to process: ", price);

                      await handlePayment(price * 100); // Ensure payment happens

                      // console.log("Payment successful. Now handling form submission.");
                      handleSubmit(e); // Proceed to handle form submission
                    } catch (error) {
                      console.error("Payment failed or was canceled:", error);
                    }
                  }}
                  disabled={submissionStatus.loading}
                >
                  Generate Hallticket
                </button>

                {renderSubmissionStatus()}
              </div>
            </>
          )
        )}
      </div>
      <ToastContainer />
    </section>
  );
}

export default Subscription;
