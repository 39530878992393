import { Outlet, useLocation } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Footer from "../../components/footer/Footer";
import StickyIcons from "../../components/stickyicons/StickyIcons";
import ScrollToTop from "../../components/scrolltotop/ScrollToTop";
import "./Layout.css";
import Scrollbar from "../../components/scrollbar/Scrollbar";

function Layout() {
  const location = useLocation();

  // Check if the current route is "/login" or "/signup"
  const hideFooterAndIcons = location.pathname === "/login" || location.pathname === "/signup";

  const hidesubscription = location.pathname === "/subscription";

  return (
    <>
      {!hideFooterAndIcons && <Navbar />}
      {!hideFooterAndIcons && !hidesubscription && <StickyIcons />}
      <div className={`outlet ${hideFooterAndIcons ? 'no-navbar' : ''}`} id="outlet">
        {!hideFooterAndIcons && <Scrollbar />}
        {!hideFooterAndIcons && <ScrollToTop />} {/* Add ScrollToTop here */}
        <Outlet />
      </div>
      {!hideFooterAndIcons && <Footer />}
    </>
  );
}

export default Layout;
