import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Auth.css';
import loginbg from "../../assets/images/poster.png";
import loginlogo from "../../assets/images/slogo.png";
import BASE_URL from '../../components/link/link';

const Login = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    mobileOrEmail: '',
    password: ''
  });
  const [errorMessage, setErrorMessage] = useState('');
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [email, setEmail] = useState('');
  const [popupErrorMessage, setPopupErrorMessage] = useState('');

  useEffect(() => {
    // Redirect logged-in users away from the login page
    const token = localStorage.getItem('token');
    if (token) {
      navigate('/'); // Redirect to home
    }
  }, [navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrorMessage(''); // Reset error message

    try {
      const response = await fetch(`${BASE_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      // console.log(formData)

      const data = await response.json();

      if (response.ok) {
        // Store token in localStorage
        localStorage.setItem('token', data.token);

        // Navigate to home page after login
        navigate('/');
        window.location.reload(); // Force a page reload to update app state
      } else {
        setErrorMessage(data.error || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('Something went wrong. Please try again later.');
    }
  };

  const handlePopupSubmit = async (e) => {
    e.preventDefault();
    setPopupErrorMessage(''); // Reset error message

    if (!email) {
      setPopupErrorMessage('Please enter a valid email.');
      return;
    }

    try {
      const response = await fetch(`${BASE_URL}/user/forgot-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email })
      });

      const data = await response.json();

      if (response.ok) {
        setPopupVisible(false); // Close the popup after successful submission
        alert('Password reset instructions have been sent to your email.');
      } else {
        setPopupErrorMessage(data.error || 'Failed to send reset instructions.');
      }
    } catch (error) {
      console.error('Error during password reset:', error);
      setPopupErrorMessage('Something went wrong. Please try again later.');
    }
  };

  const openPopup = () => {
    setPopupVisible(true);
  };

  const closePopup = () => {
    setPopupVisible(false);
    setEmail('');
  };

  return (
    <div className="auth-container">
      <div className="poster-image">
        <img src={loginbg} alt="Poster" />
      </div>

      <div className="auth-form-container">
        <div className="auth-form">
          <img src={loginlogo} alt="Logo" className="logo" />
          <h2>Login To Your Account</h2>

          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <input
                type="text"
                name="mobileOrEmail"
                placeholder="Mobile/Email*"
                value={formData.mobileOrEmail}
                onChange={handleChange}
                required
              />
            </div>

            <div className="form-group">
              <input
                type="password"
                name="password"
                placeholder="Password*"
                value={formData.password}
                onChange={handleChange}
                required
              />
            </div>

            <button type="submit" className="submit-btn">
              Login
            </button>
          </form>

          <p>
            Don't have an account? <a href="/signup">Sign Up</a>
          </p>

          <div className='forgot-password'>
            <a href="#hi" onClick={openPopup}>Forgot Password</a>
          </div>

          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
      </div>

      {/* Forgot Password Popup */}
      {isPopupVisible && (
        <div className="forgot-popup-overlay">
          <div className="forgot-popup-container">
            <h3>Reset Your Password</h3>
            <form onSubmit={handlePopupSubmit}>
              <div className="form-group">
                <input
                  type="email"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <button type="submit" className="submit-btn">Submit</button>
            </form>
            <div className="close-icon" onClick={closePopup}>&#10005;</div>
            {popupErrorMessage && <p className="error-message">{popupErrorMessage}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;