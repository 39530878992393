import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Userdata.css";
import { FaPen } from "react-icons/fa";
import BASE_URL from "../../components/link/link";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState("");
  const [editingField, setEditingField] = useState(null); // To track the field being edited
  const [updatedValue, setUpdatedValue] = useState(""); // To store the updated value

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login"); // Redirect to login page if no token is found
      return;
    }

    const fetchProfileData = async () => {
      try {
        const response = await fetch(`${BASE_URL}/user/me`, {
          method: "GET",
          headers: {
            authorization: `Bearer ${token}`,
          },
        });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(errorData.message || "Failed to fetch profile data.");
        }

        const data = await response.json();
        setUserData(data.user);
      } catch (err) {
        console.error("Error fetching profile data:", err);
        setError(err.message || "An error occurred while fetching profile data.");
      }
    };

    fetchProfileData();
  }, []);

  const handleEdit = (field, value) => {
    setEditingField(field);
    setUpdatedValue(value);
  };

  const handleInputChange = (e) => {
    setUpdatedValue(e.target.value);
  };

  const handleSave = async () => {
    if (updatedValue.trim() === "") {
      alert("Field cannot be empty!");
      return;
    }

    const token = localStorage.getItem("token");
    const fieldToUpdate = {};

    if (editingField === "Email") {
      fieldToUpdate.email = updatedValue;
    } else if (editingField === "Mobile") {
      fieldToUpdate.mobile = updatedValue;
    }

    try {
      const response = await fetch(`${BASE_URL}/user/profile/edit`, {
        method: "PUT", // Change the method to PUT
        headers: {
          "Content-Type": "application/json",
          authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(fieldToUpdate),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to update profile data.");
      }

      const data = await response.json();
      setUserData((prevData) => ({
        ...prevData,
        ...data.user, // Update only the fields that were changed
      }));
      setEditingField(null); // Stop editing
    } catch (err) {
      console.error("Error updating profile data:", err);
      setError(err.message || "An error occurred while updating profile data.");
      navigate("/login"); 
    }
  };

  const handleCancel = () => {
    setEditingField(null); // Stop editing
  };

  const getInitial = (name) => (name ? name.charAt(0).toUpperCase() : "?");

  if (error) {
    return <div className="error-message">{error}</div>;
  }

  if (!userData) {
    return <div className="loading-message">Loading profile...</div>;
  }

  return (
    <div className="profile-card">
      <div className="profile-header">
        <div className="profile-initial">{getInitial(userData.name)}</div>
        <h2>{userData.name}</h2>
      </div>
      <ul className="profile-details">
        <li>
          <strong className="profile-name">Username:</strong>
          <div className="profile-div">{userData.name}</div>
        </li>
        <li>
          <strong className="profile-name">Email:</strong>
          <div className="profile-div">
            {editingField === "Email" ? (
              <input
                type="text"
                value={updatedValue}
                onChange={handleInputChange}
                autoFocus
              />
            ) : (
              <>
                {userData.email}
                <FaPen className="edit-icon" onClick={() => handleEdit("Email", userData.email)} />
              </>
            )}
          </div>
        </li>
        <li>
          <strong className="profile-name">Mobile:</strong>
          <div className="profile-div">
            {editingField === "Mobile" ? (
              <input
                type="text"
                value={updatedValue}
                onChange={handleInputChange}
                autoFocus
              />
            ) : (
              <>
                {userData.mobile || "N/A"}
                <FaPen className="edit-icon" onClick={() => handleEdit("Mobile", userData.mobile || "")} />
              </>
            )}
          </div>
        </li>
        <li>
          <strong className="profile-name">StudentId:</strong>
          <div className="profile-div">{userData.studentId || "N/A"}</div>
        </li>
      </ul>
      {editingField && (
        <div className="profile-actions">
          <button onClick={handleSave}>Save</button>
          <button onClick={handleCancel}>Cancel</button>
        </div>
      )}
    </div>
  );
};

export default Profile;
