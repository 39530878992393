import React, { useState, useEffect } from "react";
import "./PreviousPapers.css";
import previousQuestionsImg from "../../assets/images/pdf_file_cover_page.png";
import BASE_URL from "../../components/link/link";

const PreviousPapers = () => {
  const [activeSet, setActiveSet] = useState(1);
  const [questionPapers, setQuestionPapers] = useState([]);
  const [bannerImage, setBannerImage] = useState(null);

  // Fetch question papers from the API
  useEffect(() => {
    fetch(`${BASE_URL}/pqna/all`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data)) {
          setQuestionPapers(data);
        }
      })
      .catch((error) => console.error("Error fetching question papers:", error));
  }, []);

  // Fetch banner image from the API
  useEffect(() => {
    fetch(`${BASE_URL}/banner/previousqna`)
      .then((response) => response.json())
      .then((data) => {
        if (Array.isArray(data) && data.length > 0) {
          setBannerImage(data[0].imageUrl); // Set the banner image URL from the first item
        }
      })
      .catch((error) =>
        console.error("Error fetching banner image:", error)
      );
  }, []);

  // Split data into sets for pagination
  const ITEMS_PER_SET = 4; // Number of items per set
  const paginatedData = questionPapers.reduce((sets, item, index) => {
    const setIndex = Math.floor(index / ITEMS_PER_SET) + 1;
    if (!sets[setIndex]) sets[setIndex] = [];
    sets[setIndex].push(item);
    return sets;
  }, {});

  const handleButtonClick = (setNumber) => {
    setActiveSet(setNumber);
  };

  return (
    <>
      {/* Render banner image dynamically */}
      <div className="exam-result-container">
        {bannerImage ? (
          <img
            src={bannerImage}
            alt="Exam Background"
            className="exam-result-image"
          />
        ) : (
          <p className="loading-text">Loading Banner...</p>
        )}
      </div>

      <div className="previousquestions-container">
        <h1 className="previous-question-title">
          <span>Previous </span>
          <span>Question Papers</span>
        </h1>

        <div className="question-cards-container">
          {paginatedData[activeSet]?.map((paper, index) => (
            <div key={index} className="question-card">
              <div className="question-image-container">
                <img
                  src={previousQuestionsImg}
                  alt={paper.title}
                  className="result-image"
                />
              </div>
              <div className="question-card-content">
                <span className="question-text">{paper.title}</span>
                <div className="action-buttons">
                  {/* Open PDF link */}
                  <button
                    className="action-button"
                    onClick={() => window.open(paper.pdfLink, "_blank")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z" />
                      <polyline points="14 2 14 8 20 8" />
                    </svg>
                  </button>
                  {/* Download PDF */}
                  <button
                    className="action-button"
                    onClick={() => window.open(paper.pdfLink, "_blank")}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                      <polyline points="7 10 12 15 17 10" />
                      <line x1="12" y1="15" x2="12" y2="3" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div className="number-buttons">
          {Object.keys(paginatedData).map((setNumber) => (
            <button
              key={setNumber}
              className={activeSet === Number(setNumber) ? "active" : ""}
              onClick={() => handleButtonClick(Number(setNumber))}
            >
              {setNumber}
            </button>
          ))}
        </div>
      </div>
    </>
  );
};

export default PreviousPapers;
