import React, { useState } from "react";

const MapContainer = () => {
  const [selectedLocation, setSelectedLocation] = useState("kakinada");

  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const locations = {
    kakinada:
      "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3815.7284958351615!2d82.22715343737133!3d16.98790017084474!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a38283a9f25f585%3A0xdc6db74558c5beb2!2sShyam%20Institute%2Ckakinada!5e0!3m2!1sen!2sin!4v1728984734437!5m2!1sen!2sin",
    hyderabad:
      "https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d642.0116250748633!2d78.52670735336908!3d17.368229995699586!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTfCsDIyJzA1LjciTiA3OMKwMzEnMzYuMiJF!5e0!3m2!1sen!2sin!4v1728987510298!5m2!1sen!2sin",
  };

  return (
    <div>
      <div style={{ marginBottom: "20px" }}>
        <label
          htmlFor="location-select"
          style={{ marginRight: "10px", display: "inline-block" }}
        >
          Select Location:
        </label>
        <select
          id="location-select"
          value={selectedLocation}
          onChange={handleLocationChange}
          style={{ width: "30%", padding: "10px", fontSize:"16px", marginBottom:"5px"}}
        >
          <option value="kakinada">Kakinada</option>
          <option value="hyderabad">Hyderabad</option>
        </select>
      </div>

      <div style={{ width: "100%", height: "400px", margin: "20px 0px" }}>
        <iframe
          title="Google Maps"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          src={locations[selectedLocation]}
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default MapContainer;
