import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import BASE_URL from "./link";


const useStudentId = () => {
  const [studentId, setStudentId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const checkUserStatus = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setStudentId(null);
          setIsLoading(false);
          return;
        }

        const response = await fetch(`${BASE_URL}/user/me`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setStudentId(data.user.studentId);
        } else {
          localStorage.removeItem("token");
          setStudentId(null);
          console.error("Failed to fetch user data");
        }
      } catch (error) {
        localStorage.removeItem("token");
        setStudentId(null);
        console.error("Error fetching user status:", error);
      } finally {
        setIsLoading(false);
      }
    };

    checkUserStatus();
  }, [navigate]);

  return { studentId, isLoading };
};

export default useStudentId;
