import React from 'react';
import './PrivacyAndPolicy.css'

const Privacy = () => {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      
      <h2>Introduction</h2>
      <p>
        At Shyam Institute, we are committed to protecting your privacy. This Privacy Policy outlines how we collect, use, 
        and protect your personal data when you visit our website or use our services.
      </p>
      
      <h2>Information We Collect</h2>
      <ul>
        <li>
          <strong>Personal Information</strong>: When you register for courses, submit inquiries, or contact us, we may collect 
          your name, email, phone number, and other details.
        </li>
        <li>
          <strong>Usage Data</strong>: We may collect information about how you interact with our website, such as IP address, 
          browser type, and pages visited.
        </li>
      </ul>
      
      <h2>How We Use Your Information</h2>
      <ul>
        <li>Process registrations and course inquiries.</li>
        <li>Communicate with you about training programs, updates, or promotions.</li>
        <li>Improve our website and services based on usage data.</li>
      </ul>
      
      <h2>Data Sharing</h2>
      <p>
        We do not sell or rent your personal information. We may share your data with trusted service providers (e.g., payment processors) or as required by law.
      </p>
      
      <h2>Data Security</h2>
      <p>
        We implement reasonable security measures to protect your information from unauthorized access. However, no online transmission is completely secure.
      </p>
      
      <h2>Your Rights</h2>
      <p>
        You have the right to access, update, or request the deletion of your personal data. Contact us for assistance.
      </p>

      <p>By enrolling in the training program, you confirm that you have read, understood, and agree to the Privacy Policy.</p>
    </div>
  );
};

export default Privacy;
